import React from "react";
import { Navigate } from "react-router-dom";
import { useUserContext } from "../../contexts/user_context";

interface PrivateRouteProps {
  component: React.ComponentType;
}
export const PrivateRoute = ({ component: Component }: PrivateRouteProps) => {
  const { currentUser } = useUserContext();
  return currentUser ? <Component /> : <Navigate to="/auth" />;
};
