import { ReactNode } from "react";
import styled from "styled-components";
import { IoCopyOutline, IoInformationCircleOutline } from "react-icons/io5";
import { Maybe } from "yup";
import { HStack } from "../containers/stacks";
import { H5 } from "../headings";
import { SPACE_BETWEEN_TEXT } from "../spacings";
import { RegularText, SmallText } from "../texts";
import { IconInteraction } from "../icons/icon_interaction";

export const CopyableText = ({
  label,
  value,
  elementToCopy,
  hoverMessage = "cliquer pour copier",
}: {
  label?: string;
  value?: string;
  elementToCopy: Maybe<string>;
  hoverMessage?: string;
}) => {
  const handleCopy = () => {
    void navigator.clipboard.writeText(elementToCopy ?? "");
  };
  return (
    <HStack gap={SPACE_BETWEEN_TEXT}>
      <TextContainer gap={SPACE_BETWEEN_TEXT}>
        {value && <SmallText crop={1}>{value}</SmallText>}
      </TextContainer>
      <IconInteraction
        icon={IoCopyOutline}
        interaction={handleCopy}
        content={hoverMessage}
      />
    </HStack>
  );
};
export const TextWithTooltip = ({
  label,
  value,
  infoToDisplay,
}: {
  label: string;
  value?: string;
  infoToDisplay: Maybe<string>;
}) => {
  return (
    <HStack gap={SPACE_BETWEEN_TEXT}>
      <TextContainer gap={SPACE_BETWEEN_TEXT}>
        <RegularText>{label}</RegularText> <H5 crop={1}>{value}</H5>
      </TextContainer>
      <IconInteraction
        icon={IoInformationCircleOutline}
        content={infoToDisplay ?? ""}
      />
    </HStack>
  );
};

const TextContainer = styled(HStack)`
  overflow: hidden;
  text-overflow: ellipsis;
`;
