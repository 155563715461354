import { useUserContext } from "contexts/user_context";
import React, { useEffect, useState } from "react";
import { FileUploadService } from "../application/FileUploadService";
import { Panel } from "components/ui/cards";
import { VStack } from "components/ui/containers/stacks";
import { BluePanelTitle } from "components/ui/texts";
import { FilledButton } from "components/ui/buttons";
import { SelectInput } from "components/ui/inputs/select_input";
import { FirebaseReunionGateway } from "utils/firebase/firebase.reunions";
import { Reunion } from "types/reunions.types";
import styled from "styled-components";

export const FileUpload = () => {
  const { currentUser } = useUserContext();
  const [reunions, setReunions] = useState<Reunion[]>([]);
  const [selectedReunionId, setSelectedReunionId] = useState<string | null>(
    null
  );
  useEffect(() => {
    async function fetchReunions() {
      const reunionGateway = new FirebaseReunionGateway();
      // const reunionsBis2 = await reunionGateway.getReunions();
      const fetchedReunions = await reunionGateway.getReunions();

      setReunions(fetchedReunions);
    }
    fetchReunions();
  }, []);

  const [file, setFile] = useState(null);
  const [files, setFiles] = useState<[]>();
  const [uploading, setUploading] = useState(false);

  const handleOneFileChange = (e: any) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };
  const handleFileChange = (e: any) => {
    const selectedFiles = e.target.files;
    // Vérifier si des fichiers ont été sélectionnés
    if (!selectedFiles || selectedFiles.length === 0) {
      alert("Merci de sélectionner un fichier avant de cliquer sur le bouton.");
      return;
    }
    // Mettre à jour l'état avec la liste des fichiers sélectionnés
    setFiles(selectedFiles);
    console.log("selectedFiles", selectedFiles);
  };

  const handleUploadOneFile = async () => {
    if (!file) {
      alert("Merci de sélectionner un fichier avant de cliquer sur le bouton.");
      return;
    }

    try {
      setUploading(true);
      const url = await FileUploadService.uploadFile(file);
      if (!selectedReunionId) {
        alert("Merci de sélectionner une réunion avant d'envoyer le fichier.");
        return;
      }
      await FileUploadService.addFileToReunion(
        url,
        selectedReunionId,
        currentUser?.id ?? "wCLwdi1QEbPdHwQ6IeMGWPMTSDG2"
      );
      //   window.location = url;
      console.log(url);
      setSelectedReunionId(null);
      setFile(null);
      alert("Votre fichier a bien été envoyé!");
    } catch (error) {
      console.error("Error uploading file: ", error);
      alert("Erreur pendant l'envoi du fichier. Merci de réessayez plus tard.");
    } finally {
      setUploading(false);
    }
  };

  const handleUpload = async () => {
    if (!files || files.length === 0) {
      alert(
        "Merci de sélectionner au moins un fichier avant de cliquer sur le bouton."
      );
      return;
    }

    try {
      setUploading(true);
      const fileArray = Array.from(files); // Convertir FileList en tableau
      const chunkSize = 5; // Nombre de fichiers par lot
      for (let i = 0; i < fileArray.length; i += chunkSize) {
        const chunk = fileArray.slice(i, i + chunkSize);
        const uploadPromises = chunk.map(async (file) => {
          try {
            const url = await FileUploadService.uploadFile(file);

            if (!selectedReunionId) {
              alert(
                "Merci de sélectionner une réunion avant d'envoyer le fichier."
              );
              return;
            }
            await FileUploadService.addFileToReunion(
              url,
              selectedReunionId,
              currentUser?.id ?? "wCLwdi1QEbPdHwQ6IeMGWPMTSDG2"
            );
            console.log(url);
          } catch (uploadError) {
            console.error("Error uploading file: ", uploadError);
            throw uploadError; // Re-lancer l'erreur pour qu'elle soit capturée par Promise.all
          }
        });
        await Promise.all(uploadPromises);
      }
      setSelectedReunionId(null);
      setFiles([]);
      alert("Vos fichiers ont bien été envoyés!");
    } catch (error) {
      console.error("Error uploading files: ", error);
      alert(
        "Erreur pendant l'envoi des fichiers. Merci de réessayer plus tard."
      );
    } finally {
      setUploading(false);
    }
  };

  const options = reunions.map((reunion) => {
    return {
      value: reunion.id,
      label: reunion.title,
    };
  });

  console.log("files", files);

  return (
    <VStack>
      <ReunionPanel>
        <BluePanelTitle>AJOUTER UNE IMAGE DANS UNE RÉUNION</BluePanelTitle>
        <VStack gap={30}>
          <input type="file" multiple onChange={handleFileChange} />
          <SelectInput
            label={"Choisir une réunion"}
            options={options}
            onChange={(e) => {
              setSelectedReunionId(e.value);
            }}
          />
          <VStack alignItems="center" gap={10}>
            <FilledButton
              onClick={handleUpload}
              buttonSize={"small"}
              title={uploading ? "Envoi en cours..." : "Envoyer"}
              disabled={selectedReunionId === null || uploading || !files}
            />
          </VStack>
        </VStack>
      </ReunionPanel>
    </VStack>
  );
};

const ReunionPanel = styled(Panel)`
  overflow: visible;
`;
