import React, { useContext, useEffect, useState } from "react";
import { Formik, Form, FieldArray, Field } from "formik";
import * as Yup from "yup";

import {
  SPACE_BETWEEN_STACK,
  SPACE_BETWEEN_INPUT,
  spacing,
} from "components/ui/spacings";
import { HStack, VStack } from "components/ui/containers/stacks";
import { Cotisation, UserInfos } from "types/user.types";
import { updatingUserDoc } from "utils/firebase/firebase.user";
import { ModalContext } from "components/ui/modal";
import { TextInput } from "components/ui/inputs/text_input";
import { FilledButton } from "components/ui/buttons";
import { UserContext } from "contexts/user_context";
import { Option } from "types/global_types";
import { RegularText } from "components/ui/texts";
import { InputGroup } from "components/ui/inputs/input_wrappers";
import { RadioButton } from "components/ui/inputs/radio_button";
import { SetFieldValue } from "components/ui/ui_types";
import { H4, H6 } from "components/ui/headings";
import { getFourLastLetters } from "components/profile/profile_card";
import { getCotisationFromLegacyCotisation } from "helpers/helpers";
import { cotisationsSortedByDate } from "routes/members_list/members_list_to_admin";
import { Checkbox } from "components/ui/inputs/checkbox";

export const UpdateUserCotisationsForm = ({
  user,
  updateUser,
}: {
  user: UserInfos;
  updateUser: (arg: Option<UserInfos>) => void;
}) => {
  const { handleModal } = useContext(ModalContext);

  const cotisationsUpdated = user.cotisations.map((cotisation) => {
    return getCotisationFromLegacyCotisation(cotisation);
  });

  const sortedCotisations = cotisationsSortedByDate(cotisationsUpdated);

  const initialValues: Partial<UserInfos> = {
    cotisations: sortedCotisations || [],
  };

  const validationSchema = Yup.array().of(
    Yup.object().shape(
      {
        date: Yup.date(),
        value: Yup.number(),
        paid: Yup.boolean(),
      },
      []
    )
  );

  const handleRadioButtonSelection = (
    option: Cotisation["paymentType"],
    setFieldValue: SetFieldValue,
    index: number
  ): void => {
    setFieldValue(`cotisations.${index}.paymentType`, option);
  };

  const handleUpdate = async (values: Partial<UserInfos>) => {
    const updatedData = {
      cotisations: values.cotisations,
    };

    try {
      if (user && user.id) {
        const updatedUser = await updatingUserDoc(user.id, updatedData);

        updateUser(updatedUser as Option<UserInfos>);
        handleModal();
      } else {
        throw new Error("User ID is undefined.");
      }
    } catch (error) {
      console.error("Error updating user:", error);
      alert("Oops, something went wrong.");
    }
  };

  return (
    <VStack alignItems="center" style={{ width: "100%" }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleUpdate}
      >
        {({ errors, values, setFieldValue, dirty, isValid }) => (
          <Form>
            <FieldArray name="cotisations">
              {({ insert, remove, push }) => (
                <div>
                  {values.cotisations &&
                    values.cotisations?.length > 0 &&
                    values.cotisations?.map((cotisation, index) => {
                      return (
                        <VStack
                          key={index}
                          gap={SPACE_BETWEEN_STACK}
                          alignItems="center"
                          style={{
                            width: "100%",
                            marginTop: 20,
                            marginBottom: 20,
                            borderBottom: "1px solid black",
                            paddingBottom: 20,
                          }}
                        >
                          <HStack gap={spacing.larger} alignItems="center">
                            <InputGroup>
                              <H4>
                                Cotisation {getFourLastLetters(cotisation.date)}
                              </H4>
                              <TextInput
                                id={`cotisations.${index}.date`}
                                name={`cotisations.${index}.date`}
                                type="text"
                                label={"Date du paiement"}
                                width="80%"
                                //   error={errors.date}
                              />
                              <InputGroup>
                                <RadioButton
                                  checked={cotisation.paymentType === "cheque"}
                                  onClick={() =>
                                    handleRadioButtonSelection(
                                      "cheque",
                                      setFieldValue,
                                      index
                                    )
                                  }
                                  text={"Chèque"}
                                />
                                <RadioButton
                                  checked={
                                    cotisation.paymentType === "virement"
                                  }
                                  onClick={() =>
                                    handleRadioButtonSelection(
                                      "virement",
                                      setFieldValue,
                                      index
                                    )
                                  }
                                  text={"Virement"}
                                />
                                <RadioButton
                                  checked={cotisation.paymentType === "especes"}
                                  onClick={() =>
                                    handleRadioButtonSelection(
                                      "especes",
                                      // setPaymentType,
                                      setFieldValue,
                                      index
                                    )
                                  }
                                  text={"Espèces"}
                                />
                              </InputGroup>
                              <TextInput
                                id={`cotisations.${index}.value`}
                                name={`cotisations.${index}.value`}
                                type="text"
                                label={"Montant"}
                                width="80%"
                              />
                              <TextInput
                                id={`cotisations.${index}.valueInString`}
                                name={`cotisations.${index}.valueInString`}
                                type="text"
                                label={"Montant en toutes lettres"}
                                width="80%"
                              />
                              <Checkbox
                                text={"Payé"}
                                checked={cotisation.paid}
                                onClick={() => {
                                  setFieldValue(
                                    `cotisations.${index}.paid`,
                                    !cotisation.paid
                                  );
                                }}
                              />
                            </InputGroup>
                          </HStack>
                        </VStack>
                      );
                    })}
                </div>
              )}
            </FieldArray>
            <VStack
              style={{
                position: "sticky",
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                padding: 20,
              }}
              alignItems="center"
            >
              <FilledButton
                title="Sauvegarder"
                buttonSize="small"
                type="submit"
                disabled={!isValid && dirty}
              />
            </VStack>
          </Form>
        )}
      </Formik>
    </VStack>
  );
};
