import React from "react";

import { useUserContext } from "../../contexts/user_context";
import { RegularText } from "../../components/ui/texts";
import { ProfileCard } from "../../components/profile/profile_card";
import { H4 } from "components/ui/headings";
import { PageWrapper } from "components/ui/containers/page";

export const Profile = () => {
  const { currentUser, loading, setLoading } = useUserContext();

  if (loading) {
    return <RegularText>Chargement en cours, veuillez patientez.</RegularText>;
  }

  return (
    <>
      {currentUser && (
        <PageWrapper>
          <H4>Bienvenue sur votre profil</H4>
          <ProfileCard user={currentUser}></ProfileCard>
        </PageWrapper>
      )}
    </>
  );
};
