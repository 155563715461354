import { Props as InputWrapperProps } from "./input_wrappers";
import styled, { css } from "styled-components";

export type SharedInputProps = Pick<InputWrapperProps, "label" | "error"> & {
  onValueChange?: (value: string) => void;
  width?: string;
};

export const inputBorderRadiusCSS = css`
  border-radius: 8px;
`;

export const defaultInputShapeCSS = css`
  height: 36px;
  ${inputBorderRadiusCSS};
`;
