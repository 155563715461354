import { IoPencilOutline } from "react-icons/io5"
import { FilledButton } from "./FilledButton"
type Props = {
  onClick: (data: unknown) => void
  size?: "small" | "medium" | "large" | "extraSmall"
  width?: number
}

export const PencilButton = ({ onClick, size = "small", width }: Props) => {
  return (
    <FilledButton
      buttonSize={size}
      leftIcon={IoPencilOutline}
      onClick={onClick}
      width={width}
    />
  )
}
