import { Presentation } from "components/home_page/presentation";
import { PageWrapper } from "components/ui/containers/page";
import { H4 } from "components/ui/headings";

export const PromoPresentation = () => {
  return (
    <PageWrapper>
      <H4>Mieux connaître notre promotion</H4>
      <Presentation />
    </PageWrapper>
  );
};
