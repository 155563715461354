import { useContext, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { SPACE_BETWEEN_STACK, spacing } from "components/ui/spacings";
import { HStack, VStack } from "components/ui/containers/stacks";
import { Cotisation, UserInfos } from "types/user.types";
import { updatingUserDoc } from "utils/firebase/firebase.user";
import { ModalContext } from "components/ui/modal";
import { TextInput } from "components/ui/inputs/text_input";
import { FilledButton } from "components/ui/buttons";
import { RegularText } from "components/ui/texts";
import { InputGroup } from "components/ui/inputs/input_wrappers";
import { RadioButton } from "components/ui/inputs/radio_button";
import { SetFieldValue } from "components/ui/ui_types";
import { Option } from "types/global_types";

export const AddCotisationsToMemberForm = ({
  user,
  updateUser,
}: {
  user: UserInfos;
  updateUser: (arg: Option<UserInfos>) => void;
}) => {
  const { handleModal } = useContext(ModalContext);

  const initialValues: Cotisation = {
    date: "",
    paid: false,
    paymentType: "cheque",
    value: 20,
    valueInString: "vingt euros",
  };

  const [paymentType, setPaymentType] =
    useState<Cotisation["paymentType"]>("virement");

  const validationSchema = Yup.object().shape({
    date: Yup.string()
      .matches(
        /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[0-2])\/(19|20)\d{2}$/,
        "Invalid format. Only letters and numbers are allowed."
      )
      .required("champ requis"),
    paid: Yup.boolean().required("champ requis"),
    paymentType: Yup.string().required("champ requis"),
    value: Yup.number().required("champ requis"),
    valueInString: Yup.string().required("champ requis"),
  });

  const handleRadioButtonSelection = (
    option: Cotisation["paymentType"],
    setFieldValue: SetFieldValue
  ): void => {
    setFieldValue("paymentType", option);
    setPaymentType(option);
  };

  const handleUpdate = async (values: Cotisation) => {
    const cotisationCreated = {
      date: values.date,
      paid: true,
      paymentType: values.paymentType,
      value: values.value,
      valueInString: values.valueInString,
    };
    const cotisationsUpdated = [...user.cotisations, cotisationCreated];

    try {
      if (user && user.id) {
        const updatedUser = await updatingUserDoc(user.id, {
          cotisations: cotisationsUpdated,
        });

        updateUser(updatedUser as Option<UserInfos>);
        handleModal();
      } else {
        throw new Error("User ID is undefined.");
      }
    } catch (error) {
      console.error("Error updating user:", error);
      alert("Oops, un problème est survenu.");
    }
  };

  return (
    <VStack alignItems="center" style={{ width: "100%" }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleUpdate}
      >
        {({ errors, touched, values, setFieldValue, isValid }) => {
          return (
            <Form>
              <VStack
                gap={SPACE_BETWEEN_STACK}
                alignItems="center"
                style={{ width: "100%", marginTop: 20, marginBottom: 20 }}
              >
                <HStack gap={spacing.larger} alignItems="center">
                  <InputGroup>
                    <RegularText>Créer la cotisation</RegularText>
                    <TextInput
                      id={"date"}
                      name={"date"}
                      type="text"
                      label={"Date du paiement au format jj/mm/aaaa"}
                      error={errors.date}
                    />
                    <InputGroup>
                      <RadioButton
                        checked={paymentType === "cheque"}
                        onClick={() =>
                          handleRadioButtonSelection("cheque", setFieldValue)
                        }
                        text={"Chèque"}
                      />
                      <RadioButton
                        checked={paymentType === "virement"}
                        onClick={() =>
                          handleRadioButtonSelection("virement", setFieldValue)
                        }
                        text={"Virement"}
                      />
                      <RadioButton
                        checked={paymentType === "especes"}
                        onClick={() =>
                          handleRadioButtonSelection("especes", setFieldValue)
                        }
                        text={"Espèces"}
                      />
                    </InputGroup>
                    <TextInput
                      id={"value"}
                      name={"value"}
                      type="text"
                      label={"Montant"}
                      error={errors.value}
                    />
                    <TextInput
                      id={"valueInString"}
                      name={"valueInString"}
                      type="text"
                      label={"Montant en toutes lettres"}
                      error={errors.valueInString}
                    />
                  </InputGroup>
                </HStack>
                <div>
                  <FilledButton
                    title="Sauvegarder"
                    buttonSize="small"
                    type="submit"
                    disabled={!isValid || !values.date}
                  />
                </div>
              </VStack>
            </Form>
          );
        }}
      </Formik>
    </VStack>
  );
};
