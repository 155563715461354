import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { ColorsSystem } from "components/ui/colors";

// Create styles
const styles = StyleSheet.create({
  page: {
    margin: 10,
    padding: 10,
    // width: 421,
    // height: 596,
  },
  header: {
    margin: 10,
    padding: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    height: "20%",
  },
  smallText: {
    fontSize: 10,
  },
});

// Create Document Component
export const MyDocument = () => (
  <Document>
    <Page size="A5" style={styles.page}>
      <View style={{ position: "relative" }}>
        <Text
          style={{
            position: "absolute",
            top: 40,
            right: 20,
            zIndex: 2000,
            color: ColorsSystem.black,
            fontSize: 10,
          }}
        >
          122232
        </Text>
      </View>
    </Page>
  </Document>
);
