import { Formik, Form } from "formik";
import * as Yup from "yup";

import { AddChildren } from "./add_child";
import { MaritalStatus, UserInfos } from "types/user.types";
import { HStack, VStack } from "components/ui/containers/stacks";
import { FilledButton } from "components/ui/buttons";
import { TextInput } from "components/ui/inputs/text_input";
import styled from "styled-components";
import { ColorsSystem } from "components/ui/colors";
import { OptionType, SelectInput } from "components/ui/inputs/select_input";
import { RadioButton } from "components/ui/inputs/radio_button";
import { SPACE_BETWEEN_INPUT } from "components/ui/spacings";
import { TextAreaInput } from "components/ui/inputs/text_area_input";
import { RegularText } from "components/ui/texts";

const validationSchema = Yup.object().shape({
  type: Yup.number().typeError("utilisez des chiffres uniquement"),
  lastName: Yup.string(),
  firstName: Yup.string(),
  login: Yup.string(),
  landLineNumber: Yup.number().nullable(),
  phoneNumber: Yup.number().nullable(),
  civilAdress: Yup.object()
    .shape({
      numberAdress: Yup.number(), //.required("Numéro de rue obligatoire"),
      adress: Yup.string(), //.required("Nom de rue obligatoire"),
      zipcode: Yup.number().typeError("utilisez des chiffres uniquement"), //.required("Code postal obligatoire"),
      city: Yup.string(), //.required("Nom de la ville obligatoire"),
      country: Yup.string(), //.required("Nom du pays obligatoire"),
    })
    .nullable(),
  region: Yup.string().nullable(),
  diploma: Yup.string().nullable(),
  birthdate: Yup.string().nullable(),
  grade: Yup.string().nullable(),
  DATECEN: Yup.string().nullable(),
  DATE1LCL: Yup.string().nullable(),
  DATE2CL: Yup.string().nullable(),
  brigade: Yup.string().nullable(),
  army: Yup.string().nullable(),
  originArmy: Yup.string(),
  maritalSituation: Yup.object()
    .shape({
      maritalStatus: Yup.string(),
      wifeName: Yup.string(),
      moreInfos: Yup.string(),
      childrenCount: Yup.number(),
    })
    .nullable(),
  children: Yup.array().of(
    Yup.object()
      .shape(
        {
          name: Yup.string().typeError("utilisez des lettres uniquement"),
          birthdate: Yup.string().typeError("utilisez des lettres uniquement"),
        },
        [["name", "birthdate"]]
      )
      .nullable()
  ),
  permanentAdress: Yup.object()
    .shape({
      numberAdress: Yup.number().typeError("utilisez des chiffres uniquement"),
      adress: Yup.string().typeError("utilisez des lettres uniquement"),
      zipcode: Yup.number().typeError("utilisez des chiffres uniquement"),
      city: Yup.string().typeError("utilisez des lettres uniquement"),
      country: Yup.string().typeError("utilisez des lettres uniquement"),
    })
    .nullable(),
  repReunion: Yup.number().nullable(),
  promotion: Yup.string().nullable(),
  deceased: Yup.object()
    .shape({
      state: Yup.boolean(),
      date: Yup.string().typeError("utilisez des lettres uniquement"),
      place: Yup.string().typeError("utilisez des lettres uniquement"),
      widowNumber: Yup.number().typeError("utilisez des chiffres uniquement"),
    })
    .nullable(),
});

export type UpdateMemberFormProps = {
  handleSubmit: (values: Partial<UserInfos>) => void;
  member: UserInfos;
};

export const UpdateMemberForm = ({
  handleSubmit,
  member,
}: UpdateMemberFormProps) => {
  const maritaStatusOptions: OptionType[] = [
    {
      value: "Célibataire",
      label: "Célibataire",
    },
    {
      value: "Marié",
      label: "Marié",
    },
    {
      value: "Divorcé",
      label: "Divorcé",
    },
    {
      value: "Veuf",
      label: "Veuf",
    },
    {
      value: "Remarié",
      label: "Remarié",
    },
  ];

  const getMemberMaritalStatus = (maritalStatus: MaritalStatus) => {
    if (maritalStatus[0] === "M") {
      return "Marié";
    } else if (maritalStatus[0] === "C") {
      return "Célibataire";
    } else if (maritalStatus[0] === "R") {
      return "Remarié";
    } else if (maritalStatus[0] === "D") {
      return "Divorcé";
    } else if (maritalStatus[0] === "V") {
      return "Veuf";
    }
    return maritalStatus;
  };

  return (
    <VStack>
      <Formik
        initialValues={member}
        onSubmit={(values) => handleSubmit(values)}
        validationSchema={validationSchema}
      >
        {({ dirty, values, errors, setFieldValue, isValid }) => {
          return (
            <Form>
              <VStack gap={20}>
                <HStack gap={20}>
                  <InfosWrapper>
                    <InfosTitle>Infos de base</InfosTitle>
                    <TextInput
                      id={"firstName"}
                      name={"firstName"}
                      type="text"
                      label={"Prénom"}
                      error={errors.firstName}
                    />
                    <TextInput
                      id={"lastName"}
                      name={"lastName"}
                      type="text"
                      label={"Nom"}
                      error={errors.lastName}
                    />
                    <TextInput
                      label="Date de naissance"
                      type="text"
                      name={"birthdate"}
                      id={"birthdate"}
                      placeholder="JJ/MM/AAAA"
                      error={errors.birthdate}
                    />
                    <TextInput
                      label="Login"
                      required
                      type="login"
                      id="login"
                      name="login"
                      error={errors.login}
                    />
                  </InfosWrapper>
                  <InfosWrapper>
                    <InfosTitle>Infos de contacts</InfosTitle>
                    <TextInput
                      id={"phoneNumber"}
                      name={"phoneNumber"}
                      type="text"
                      label={"Numéro portable"}
                      error={errors.phoneNumber}
                    />
                    <TextInput
                      id={"landLineNumber"}
                      name={"landLineNumber"}
                      type="text"
                      label={"Numéro ligne fixe"}
                      error={errors.landLineNumber}
                    />
                    <InfosTitle>Infos supplémentaires</InfosTitle>
                    <RadioButton
                      text="Décédé"
                      onClick={() => {
                        setFieldValue("isDeceased", !values.isDeceased);
                      }}
                      checked={values.isDeceased}
                    />
                    {values.isDeceased && (
                      <VStack gap={SPACE_BETWEEN_INPUT}>
                        <TextInput
                          id={"deceasedData.date"}
                          name={"deceasedData.date"}
                          type="text"
                          label={"Date de décès"}
                          placeholder="JJ/MM/AAAA"
                          error={errors.deceasedData}
                        />
                        <TextInput
                          id={"deceasedData.place"}
                          name={"deceasedData.place"}
                          type="text"
                          label={"Lieu de décès"}
                          error={errors.deceasedData}
                        />
                        <TextInput
                          id={"deceasedData.widowNumber"}
                          name={"deceasedData.widowNumber"}
                          type="text"
                          label={"Numéro veuve"}
                          error={errors.deceasedData}
                        />
                      </VStack>
                    )}
                  </InfosWrapper>
                  <InfosWrapper>
                    <InfosTitle>Infos Famille</InfosTitle>
                    <SelectInput
                      defaultValue={maritaStatusOptions.find(
                        (option) =>
                          option.value ===
                          getMemberMaritalStatus(
                            values.maritalSituation.maritalStatus
                          )
                      )}
                      label="Statut marital"
                      options={maritaStatusOptions}
                      onChange={(option) => {
                        setFieldValue(
                          "maritalSituation.maritalStatus",
                          option.value
                        );
                      }}
                    />
                    <TextInput
                      id={"maritalSituation.wifeName"}
                      name={"maritalSituation.wifeName"}
                      type="text"
                      label={"Nom de l'épouse"}
                      error={errors.maritalSituation?.wifeName}
                    />
                    <VStack gap={10}>
                      <RegularText>Enfants</RegularText>
                      <AddChildren
                        childrenValues={values.children}
                        name={"children"}
                        id={"children"}
                      />
                    </VStack>
                  </InfosWrapper>
                </HStack>

                <InfosWrapper>
                  <InfosTitle> Infos Adresses</InfosTitle>
                  <HStack gap={60}>
                    <VStack>
                      <RegularText>Adresse Civile</RegularText>
                      <TextInput
                        id={"civilAdress.numberAdress"}
                        name={"civilAdress.numberAdress"}
                        type="text"
                        label={"Numéro de rue"}
                        error={errors.civilAdress?.numberAdress}
                      />
                      <TextInput
                        id={"civilAdress.adress"}
                        name={"civilAdress.adress"}
                        type="text"
                        label={"Rue"}
                        error={errors.civilAdress?.adress}
                      />
                      <TextInput
                        id={"civilAdress.zipcode"}
                        name={"civilAdress.zipcode"}
                        type="text"
                        label={"Code postal"}
                        error={errors.civilAdress?.zipcode}
                      />
                      <TextInput
                        id={"civilAdress.city"}
                        name={"civilAdress.city"}
                        type="text"
                        label={"Ville"}
                        error={errors.civilAdress?.city}
                      />
                      <TextInput
                        id={"civilAdress.country"}
                        name={"civilAdress.country"}
                        type="text"
                        label={"Pays"}
                        error={errors.civilAdress?.country}
                      />
                    </VStack>
                    <VStack>
                      <RegularText>Adresse Permanente</RegularText>
                      <TextInput
                        id={"permanentAdress.numberAdress"}
                        name={"permanentAdress.numberAdress"}
                        type="text"
                        label={"Numéro de rue"}
                        error={errors.permanentAdress?.numberAdress}
                      />
                      <TextInput
                        id={"permanentAdress.adress"}
                        name={"permanentAdress.adress"}
                        type="text"
                        label={"Rue"}
                        error={errors.permanentAdress?.adress}
                      />
                      <TextInput
                        id={"permanentAdress.zipcode"}
                        name={"permanentAdress.zipcode"}
                        type="text"
                        label={"Code postal"}
                        error={errors.permanentAdress?.zipcode}
                      />
                      <TextInput
                        id={"permanentAdress.city"}
                        name={"permanentAdress.city"}
                        type="text"
                        label={"Ville"}
                        error={errors.permanentAdress?.city}
                      />
                      <TextInput
                        id={"permanentAdress.country"}
                        name={"permanentAdress.country"}
                        type="text"
                        label={"Pays"}
                        error={errors.permanentAdress?.country}
                      />
                    </VStack>
                  </HStack>
                </InfosWrapper>

                <HStack gap={20}>
                  <InfosWrapper>
                    <InfosTitle>Infos carrière</InfosTitle>
                    <HStack gap={60}>
                      <VStack>
                        <TextInput
                          id={"promotion"}
                          name={"promotion"}
                          type="text"
                          label={"Promotion"}
                          error={errors.promotion}
                        />
                        <TextInput
                          id={"originArmy"}
                          name={"originArmy"}
                          type="text"
                          label={"Armée d'origine"}
                          error={errors.originArmy}
                        />
                        <TextInput
                          id={"army"}
                          name={"army"}
                          type="text"
                          label={"Armée"}
                          error={errors.army}
                        />
                        <TextInput
                          id={"brigade"}
                          name={"brigade"}
                          type="text"
                          label={"Brigade"}
                          error={errors.brigade}
                        />
                      </VStack>
                      <VStack>
                        <TextInput
                          id={"DATECEN"}
                          name={"DATECEN"}
                          type="text"
                          label={"DATECEN"}
                          error={errors.DATECEN}
                        />
                        <TextInput
                          id={"DATE1LCL"}
                          name={"DATE1LCL"}
                          type="text"
                          label={"DATE1LCL"}
                          error={errors.DATE1LCL}
                        />
                        <TextInput
                          id={"DATE2CL"}
                          name={"DATE2CL"}
                          type="text"
                          label={"DATE2CL"}
                          error={errors.DATE2CL}
                        />
                        <TextInput
                          id={"diploma"}
                          name={"diploma"}
                          type="text"
                          label={"Diplôme"}
                          error={errors.diploma}
                        />
                        <TextInput
                          id={"grade"}
                          name={"grade"}
                          type="text"
                          label={"Grade"}
                          error={errors.grade}
                        />
                      </VStack>
                    </HStack>
                  </InfosWrapper>

                  <InfosWrapper>
                    <VStack gap={20} style={{ minWidth: 400 }}>
                      <InfosTitle>Infos supplémentaires</InfosTitle>
                      <TextAreaInput
                        id={"moreInfos"}
                        name={"moreInfos"}
                        error={errors.moreInfos}
                        width="100%"
                        numberOfRows={10}
                      />
                    </VStack>
                  </InfosWrapper>
                </HStack>
              </VStack>

              <VStack
                style={{
                  position: "sticky",
                  bottom: 0,
                  background:
                    "linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)",
                  paddingTop: 40,
                }}
                alignItems="center"
              >
                <FilledButton
                  title="Sauvegarder"
                  buttonSize="medium"
                  type={"submit"}
                  disabled={!isValid}
                />
              </VStack>
            </Form>
          );
        }}
      </Formik>
    </VStack>
  );
};

const InfosWrapper = styled(VStack)`
  padding: 10px 40px 20px 20px;
  gap: 10px;
  border: 3px solid ${ColorsSystem.powderBlueOne};
  border-radius: 5px;
`;

const InfosTitle = styled(RegularText)`
  color: ${ColorsSystem.primary_active};
`;
