import { CSSProperties } from "react";
import styled, { css } from "styled-components";
import { getCSSUnit } from "./layouts_helpers";
import { ColorsSystem } from "./colors";

type PanelProps = {
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
  padding?: CSSProperties["padding"];
  margin?: CSSProperties["margin"];
  bgImage?: CSSProperties["backgroundImage"];
};

export const Panel = styled.div<PanelProps>`
  box-sizing: border-box;
  border-radius: 10px;
  background: ${ColorsSystem.white};
  box-shadow: 0px 0px 10px #00000026;
  width: ${({ width }) => (width ? getCSSUnit(width) : undefined)};
  height: ${({ height }) => (height ? getCSSUnit(height) : undefined)};
  padding: ${({ padding }) => getCSSUnit(padding || 24)};
  margin: ${({ margin }) => getCSSUnit(margin || 0)};
  overflow: hidden;
  ${(props) =>
    props.bgImage &&
    css`
      width: 100%;
      background: linear-gradient(rgba(6, 6, 7, 0.3), rgba(14, 16, 19, 0.3)),
        url("${props.bgImage}");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    `}
`;
