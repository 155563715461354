import { useState, useContext } from "react";
import { resetPassword } from "../../utils/firebase/firebase.utils";
import { VStack } from "components/ui/containers/stacks";
import styled from "styled-components";
import { ModalContext } from "components/ui/modal";
import { RegularText } from "components/ui/texts";
import { FilledButton } from "components/ui/buttons";
import { H4 } from "components/ui/headings";
import { commonInputCSS } from "components/ui/inputs/text_input";
import { validateEmail } from "utils/functions/inputs_validation";

export const PasswordResetModal = () => {
  const [email, setEmail] = useState<string>("");
  const { handleModal } = useContext(ModalContext);

  const handlePasswordReset = (email: string) => {
    resetPassword(email);
  };

  const handleSubmit = () => {
    const validationError = validateEmail(email); // Vérification de l'email

    if (email && !validationError) {
      handlePasswordReset(email);
      handleModal();
    } else {
      alert("Veuillez entrer une adresse email valide.");
    }
  };

  return (
    <Wrapper alignItems="center">
      <H4>Réinitialiser le mot de passe</H4>
      <RegularText>
        Entrez votre e-mail de connexion au site pour recevoir un email de
        réinitialisation de votre mot de passe
      </RegularText>
      <TextInputContainer
        type="email"
        placeholder="Entrez votre email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        isValid={true}
      />
      <ButtonWrapper>
        <FilledButton
          title="Demander un nouveau mot de passe"
          buttonSize="small"
          onClick={handleSubmit}
        />
      </ButtonWrapper>
    </Wrapper>
  );
};

export const TextInputContainer = styled.input`
  ${commonInputCSS};
`;

const Wrapper = styled(VStack)`
  align-items: center;
  gap: 20px;
  z-index: 100;
`;

const ButtonWrapper = styled(VStack)`
  padding-top: 20px;
  align-items: center;
  gap: 10px;
`;
