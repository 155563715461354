export const spacing = {
  smallest: 4,
  smaller: 8,
  small: 16,
  medium: 24,
  large: 32,
  larger: 56,
};

export const SPACE_BETWEEN_ELEMENT_INNER_STACK = spacing.small;
export const SPACE_BETWEEN_TEXT = spacing.smaller;
export const SPACE_BETWEEN_STACK = spacing.large;
export const SPACE_BETWEEN_INPUT = spacing.smaller;
export const PADDING_CARD = spacing.medium;
export const PADDING_PANEL = spacing.medium;
export const SPACE_ABOVE_PAGE_TITLE = spacing.larger;
export const SPACE_ON_TOP_OF_ELEMENT_STICKY = 20;
