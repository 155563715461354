import styled from "styled-components";
import { VStack } from "./stacks";

export const PageWrapper = styled(VStack)`
  gap: 30px;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 60px;
  padding-left: 50px;
  padding-right: 50px;
`;
