import { ColorsSystem } from "components/ui/colors";
import { ImageDisplay } from "components/ui/image_display";
import { ModalContext } from "components/ui/modal";
import { HStack, VStack } from "components/ui/containers/stacks";
import { useContext } from "react";
import styled, { CSSProperties, css } from "styled-components";

type Presentation = { id: number; name: string; path: string };
export const Presentation = () => {
  const { handleModal } = useContext(ModalContext);

  const Image1_1 = require("assets/home_img/P1.1.jpg");
  const Image1_2 = require("assets/home_img/P1.2.jpg");
  const Image2 = require("assets/home_img/P2.jpg");
  const Image3 = require("assets/home_img/P3.jpg");
  const Image4 = require("assets/home_img/P4.jpg");
  const Image5 = require("assets/home_img/P5.jpg");
  const Image6_1 = require("assets/home_img/P6.1.jpg");
  const Image6_2 = require("assets/home_img/P6.2.jpg");
  const Image7_1 = require("assets/home_img/P7.1.jpg");
  const Image7_2 = require("assets/home_img/P7.2.jpg");
  const Image8 = require("assets/home_img/P8.jpg");
  const Image9 = require("assets/home_img/P9.jpg");
  const Image10 = require("assets/home_img/P10.jpg");
  const Image11 = require("assets/home_img/P11.jpg");
  const Image12 = require("assets/home_img/P12.jpg");

  return (
    <>
      <VStack fullWidth style={{ paddingInline: 60 }}>
        <GridWrapper>
          <Logo>
            <Image src={Image1_1} />
          </Logo>
          <TextLogo>
            <Image src={Image1_2} />
          </TextLogo>
          <Flag>
            <Image src={Image2} />
          </Flag>
          <Glaurier>
            <Image src={Image3} />
          </Glaurier>
          <Citation1>
            <Image src={Image6_1} />
          </Citation1>
          <Citation2>
            <Image src={Image6_2} />
          </Citation2>
          <Citation3>
            <Image src={Image7_1} />
          </Citation3>
          <Citation4>
            <Image src={Image7_2} />
          </Citation4>
          <Text1>
            <Image src={Image4} />
          </Text1>
          <Text2>
            <Image src={Image5} />
          </Text2>
          <Message1>
            <Image src={Image8} />
          </Message1>
          <Message2>
            <Image src={Image9} />
          </Message2>
          <MessageOfficiel>
            <Image src={Image10} />
          </MessageOfficiel>
          <PhotoGeneral>
            <Image src={Image11} />
          </PhotoGeneral>
          <MotDuGeneral>
            <Image src={Image12} />
          </MotDuGeneral>
        </GridWrapper>
      </VStack>
    </>
  );
};

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  grid-auto-rows: minmax(100px, auto);
  padding: 50px;
`;

type PanelProps = {
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
  padding?: CSSProperties["padding"];
  margin?: CSSProperties["margin"];
  bgImage?: CSSProperties["backgroundImage"];
};

export const Panel = styled.div<PanelProps>`
  border-radius: 10px;
  background: ${ColorsSystem.white};
  box-shadow: 0px 0px 10px #00000026;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  ${(props) =>
    props.bgImage &&
    css`
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    `}
`;

const Logo = styled(Panel)`
  grid-column: 1/2;
  grid-row: 1;
`;
const TextLogo = styled(Panel)`
  grid-column: 2/4;
  grid-row: 1;
`;
const Glaurier = styled(Panel)`
  grid-column: 4/5;
  grid-row: 1;
`;
const Flag = styled(Panel)`
  grid-column: 1/3;
  grid-row: 2/4;
`;
const Citation1 = styled(Panel)`
  grid-column: 3/5;
  grid-row: 2;
`;
const Citation2 = styled(Panel)`
  grid-column: 3/5;
  grid-row: 3;
`;
const Citation3 = styled(Panel)`
  grid-column: 1/3;
  grid-row: 4;
`;
const Citation4 = styled(Panel)`
  grid-column: 1/3;
  grid-row: 5;
`;
const Text1 = styled(Panel)`
  grid-column: 3/5;
  grid-row: 4/6;
`;
const Text2 = styled(Panel)`
  grid-column: 3/5;
  grid-row: 6/7;
`;
const MessageOfficiel = styled(Panel)`
  grid-column: 3/5;
  grid-row: 7/9;
`;
const Message1 = styled(Panel)`
  grid-column: 1/3;
  grid-row: 6/8;
`;
const Message2 = styled(Panel)`
  grid-column: 1/3;
  grid-row: 8/9;
`;
const PhotoGeneral = styled(Panel)`
  grid-column: 1/3;
  grid-row: 9/10;
`;
const MotDuGeneral = styled(Panel)`
  grid-column: 3/5;
  grid-row: 9/10;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  // cursor: pointer;
`;
