import React from "react";
import styled, { css } from "styled-components";
import { FiLoader } from "react-icons/fi";
import {
  largeStyle,
  mediumStyle,
  smallStyle,
  commonButtonStyle,
  ButtonProps,
  extraSmallStyle,
} from "./config";
import { ColorsSystem } from "../colors";
import { Icon } from "../icon";
import { getCSSUnit } from "../layouts_helpers";

export const OutlineButton: React.FC<ButtonProps> = ({
  buttonSize = "medium",
  title = "",
  disabled = false,
  loading = false,
  leftIcon = undefined,
  rightIcon = undefined,
  href,
  ...props
}) => {
  return (
    <OutlineContainer
      {...(href
        ? ({
            as: "a",
            href,
          } as any)
        : {})}
      type={props.type ?? "button"}
      disabled={disabled}
      buttonSize={buttonSize}
      {...props}
    >
      {loading ? (
        <Icon Component={FiLoader} size={buttonSize === "small" ? 16 : 24} />
      ) : (
        <>
          {leftIcon && (
            <Icon
              Component={leftIcon}
              size={buttonSize === "small" ? 16 : 24}
            />
          )}
          {title && title}
          {rightIcon && (
            <Icon
              Component={rightIcon}
              size={buttonSize === "small" ? 16 : 24}
            />
          )}
        </>
      )}
    </OutlineContainer>
  );
};

const OutlineContainer = styled.button<ButtonProps>`
  ${commonButtonStyle};
  background-color: transparent;
  border: 2px solid ${({ color }) => color || ColorsSystem.blue};
  color: ${({ color }) => color || ColorsSystem.blue};
  width: ${({ width }) => (width ? getCSSUnit(width) : "max-content")};
  ${({ buttonSize }) => {
    switch (buttonSize) {
      case "large":
        return largeStyle;
      case "medium":
        return mediumStyle;
      case "small":
        return smallStyle;
      default:
        return extraSmallStyle;
    }
  }}
  &:hover {
    color: ${ColorsSystem.primary_hover};
    border: 2px solid ${ColorsSystem.primary_hover};
  }
  &:active {
    color: ${ColorsSystem.primary_active};
    border: 2px solid ${ColorsSystem.primary_active};
  }
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${ColorsSystem.lightGrey};
      border: 2px solid ${ColorsSystem.lightGrey};
      cursor: not-allowed;
      &:hover {
        color: ${ColorsSystem.lightGrey};
        border: 2px solid ${ColorsSystem.lightGrey};
      }
      &:active {
        color: ${ColorsSystem.lightGrey};
        border: 2px solid ${ColorsSystem.lightGrey};
      }
    `};
  ${({ error }) =>
    error &&
    css`
      color: ${ColorsSystem.red};
      border: 2px solid ${ColorsSystem.red};
      &:hover {
        color: ${ColorsSystem.red};
        border: 2px solid ${ColorsSystem.red};
      }
      &:active {
        color: ${ColorsSystem.red};
        border: 2px solid ${ColorsSystem.red};
      }
    `};
  ${({ centered }) =>
    centered &&
    css`
      align-self: center;
    `};
`;
