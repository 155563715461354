import { ErrorMessage, Field } from "formik";
import { ChangeEvent, InputHTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { ColorsSystem } from "../colors";
import { RegularText } from "../texts";
import { LabelText } from "./date_picker_input";
import { VStack } from "../containers/stacks";
import { spacing } from "../spacings";

import { forwardRef, Ref } from "react";
import Select, { SelectComponentsConfig } from "react-select";
import { Option } from "types/global_types";
import {
  InputWrapperWithErrorMessage,
  Props as InputWrapperProps,
} from "./input_wrappers";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  backgroundColor?: string;
  borderColor?: string;
  type?: string;
  onChange?: (params?: any) => void;
  onChangeCheckbox?: () => void;
  onChangeInput?: (e: ChangeEvent<HTMLInputElement>) => void;
  values?: any;
  placeholder?: string;
  stretchInput?: boolean;
  editable?: boolean;
  fontSize?: number;
  fontFamily?: string;
  colorTitle?: string;
  marginBottom?: number;
  marginTop?: number;
  minHeight?: number;
  minWidth?: number;
  marginLeft?: number;
  marginRight?: number;
}

const inputWidth = "40rem";

// export const Select = styled.select<InputProps>`
//   background-color: ${(props) => props.backgroundColor ?? "transparent"};
//   border: 1px solid ${ColorsSystem.blue};
//   padding: 10px 3px;
//   border-radius: 3px;
//   width: ${(props) => props.width ?? inputWidth};
//   font-family: "Branding-Medium";
//   font-size: 1.2rem;
//   &::placeholder,
//   ::-webkit-input-placeholder {
//     color: grey;
//     font-size: 1.2rem;
//     opacity: 1;
//   }
//   ${(props) =>
//     props.stretchInput &&
//     css`
//       width: 100%;
//     `}
// `;

export const Label = styled.label`
  padding-bottom: ${spacing.small}px;
  color: ${(props) => props.color ?? ColorsSystem.blue};
`;
// export const SelectInputOne = (props: any) => {
//   const { label, name, options, marginBottom, id, color, ...rest } = props;
//   return (
//     <VStack>
//       {label && (
//         <Label htmlFor={id}>
//           <LabelText color={color}>{label}</LabelText>
//         </Label>
//       )}
//       <Field as={Select} id={name} name={name} {...rest}>
//         {options.map((option: any) => {
//           return (
//             <option
//               key={option.value}
//               value={option.value}
//               selected={option.selected}
//               hidden={option.hidden}
//               disabled={option.disabled}
//             >
//               {option.key}
//             </option>
//           );
//         })}
//       </Field>
//       <ErrorMessage name={props.name as string}>
//         {(message) => <RegularText color={"red"}>{message}</RegularText>}
//       </ErrorMessage>
//     </VStack>
//   );
// };

export type OptionType = {
  value: Option<string>;
  label: string;
  selected?: boolean;
  hidden?: boolean;
  disabled?: boolean;
};

const FONT_SIZE = "16px";

export type SelectInputProps = Pick<InputWrapperProps, "label" | "error"> & {
  options: OptionType[];
  placeholder?: string;
  onChange?: (e: any) => void;
  defaultValue?: OptionType;
  components?: SelectComponentsConfig<any, any, any>;
  styles?: any;
  isMulti?: boolean;
};
const MIN_WIDTH = "256px";
const MAX_WIDTH = "448px";
const WIDTH = "100%";

export const SelectInput = forwardRef(
  (
    {
      options = [],
      placeholder = "Choisir une option",
      label,
      error,
      defaultValue,
      isMulti,
      ...props
    }: SelectInputProps,
    ref: Ref<any> | null
  ) => {
    return (
      <InputWrapperWithErrorMessage label={label} error={error}>
        <Select
          isMulti={isMulti}
          options={options}
          value={defaultValue}
          defaultInputValue={options.find((option) => option.selected)?.label}
          ref={ref}
          {...props}
          isOptionSelected={(option) => option.selected}
          placeholder={placeholder}
          styles={{
            control: (base, props) => ({
              ...base,
              height: "42px",
              fontSize: FONT_SIZE,
              lineHeight: "24px",
              fontFamily: "Montserrat",
              borderRadius: "8px",
              borderColor: ColorsSystem.darkGrey,
              borderWidth: props.isFocused ? 2 : 1,
              minWidth: MIN_WIDTH,
              width: WIDTH,
              maxWidth: MAX_WIDTH,
            }),
            option: (base) => ({
              ...base,
              fontFamily: "Montserrat",
              color: ColorsSystem.darkGrey,
              fontSize: FONT_SIZE,
              lineHeight: "24px",
            }),
            placeholder: (base) => ({
              ...base,
              fontFamily: "Montserrat",
              fontSize: FONT_SIZE,
              lineHeight: "24px",
            }),

            menu: (base) => ({
              ...base,
              minWidth: MIN_WIDTH,
              width: WIDTH,
              maxWidth: MAX_WIDTH,
            }),

            ...props.styles,
          }}
        />
      </InputWrapperWithErrorMessage>
    );
  }
);
