import React, {
  ChangeEvent,
  forwardRef,
  InputHTMLAttributes,
  Ref,
} from "react";
import { FiSearch } from "react-icons/fi";
import { IconType } from "react-icons/lib";
import styled from "styled-components";
import { SharedInputProps } from "./input_config";
import { InputWrapperWithErrorMessage } from "./input_wrappers";
import { TextInputContainer } from "./text_input";
import { getCSSUnit } from "../layouts_helpers";
import { Icon as RawIcon } from "../icon";

export type InputWidth = "small" | "medium" | "full";

type IconInputProps = InputHTMLAttributes<HTMLInputElement> &
  SharedInputProps & {
    inputType?: string;
    Icon?: IconType;
    onIconClick?: () => void;
    width?: InputWidth;
  };

export const IconInput = forwardRef(
  (
    {
      onValueChange,
      label,
      error,
      inputType = "text",
      Icon = FiSearch,
      onIconClick,
      width = "full",
      ...props
    }: IconInputProps,
    ref: Ref<HTMLInputElement>
  ) => {
    return (
      <InputWrapperWithErrorMessage error={error} label={label} width={width}>
        <Container width={width}>
          <TextInputContainer
            {...props}
            type={inputType}
            isValid={!error}
            ref={ref}
            style={{ width: "100%" }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              props.onChange?.(event);
              onValueChange?.(event.currentTarget.value);
            }}
          />
          <IconWrapper>
            <RawIcon Component={Icon} onClick={onIconClick} />
          </IconWrapper>
        </Container>
      </InputWrapperWithErrorMessage>
    );
  }
);

const Container = styled.div<{ width?: string | number }>`
  display: flex;
  flex-direction: row;
  position: relative;
  width: ${({ width }) => (width ? `${getCSSUnit(width)}` : "100%")};
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 6px;
  display: flex;
`;
