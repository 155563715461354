import React from "react";

import { FieldArray, FieldArrayRenderProps } from "formik";

import { TextInput } from "components/ui/inputs/text_input";
import { FilledButton, OutlineButton } from "components/ui/buttons";
import { HStack, VStack } from "components/ui/containers/stacks";
import { ColorsSystem } from "components/ui/colors";
import { IoTrash } from "react-icons/io5";

type Child = { name: string; birthdate: string };
type AddChildrenProps = {
  childrenValues: Child[];
  name: string;
  id: string;
};

export const AddChildren = ({ childrenValues, name }: AddChildrenProps) => {
  const validChildrenValues = childrenValues.filter((value) => value !== null);
  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <VStack gap={5}>
          {validChildrenValues && validChildrenValues.length > 0 ? (
            validChildrenValues.map((value: Child, index: number) => {
              const isLastItemOfEntitiesArray =
                index === validChildrenValues.length - 1;
              return (
                <>
                  <HStack
                    justifyContent="flex-start"
                    gap={5}
                    alignItems="end"
                    key={index}
                  >
                    <TextInput
                      label="Prénom"
                      type="text"
                      name={`${name}.${index}.name`}
                      id={`${name}`}
                    />
                    <TextInput
                      label="Date de naissance"
                      type="text"
                      name={`${name}.${index}.birthdate`}
                      id={`${name}`}
                      placeholder="JJ/MM/AAAA"
                    />

                    <IoTrash
                      color={ColorsSystem.red}
                      onClick={handleRemoveChildren(arrayHelpers, index)}
                      size={46}
                    />
                  </HStack>
                  {isLastItemOfEntitiesArray && (
                    <VStack>
                      <OutlineButton
                        title="Ajouter un enfant"
                        buttonSize="extraSmall"
                        onClick={handleAddChild(arrayHelpers)}
                      />
                    </VStack>
                  )}
                </>
              );
            })
          ) : (
            <VStack alignItems="center">
              <FilledButton
                buttonSize="extraSmall"
                onClick={handleAddChild(arrayHelpers)}
                title="Ajouter un enfant"
              />
            </VStack>
          )}
        </VStack>
      )}
    />
  );
};

const handleRemoveChildren =
  (arrayHelpers: FieldArrayRenderProps, index: number) => () => {
    arrayHelpers.remove(index);
  };
const handleAddChild = (arrayHelpers: FieldArrayRenderProps) => () => {
  return arrayHelpers.push({ name: "", birthdate: "" });
};

const { v4: uuidv4 } = require("uuid");
uuidv4(); // ⇨ '1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed'
