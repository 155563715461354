import { db } from "./firebase.utils";
import { Reunion } from "types/reunions.types";
import { doc, getDoc, query, collection, getDocs } from "firebase/firestore";
// const admin = require("firebase-admin");
// import * as admin from "firebase-admin";

export const MEETINGS_COLLECTION = "reunions";

export class FirebaseReunionGateway {
  async getReunions(): Promise<Reunion[]> {
    const collectionRef = collection(db, MEETINGS_COLLECTION);
    const q = query(collectionRef);
    const querySnapshot = await getDocs(q);
    const documents = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    })) as Reunion[];
    return documents;
  }

  async getReunionById(reunionId: string): Promise<Reunion | null> {
    try {
      const docRef = doc(db, MEETINGS_COLLECTION, reunionId);
      const reunionDoc = await getDoc(docRef);

      if (!reunionDoc.exists) {
        throw new Error(`Reunion ${reunionId} does not exist in Firebase`);
      }

      const reunion = reunionDoc.data() as Reunion;
      return reunion;
    } catch (err) {
      console.error("Error getting reunion", err);
      throw err; // Re-throw the error to propagate it
    }
  }
}
