import { Panel } from "components/ui/cards";
import { HStack, VStack } from "components/ui/containers/stacks";
import { H4, H5 } from "components/ui/headings";
import { RegularText, SmallText } from "components/ui/texts";
import { FiArrowRight } from "react-icons/fi";
import styled from "styled-components";

type GalleryCardProps = {
  backgroundImage: string;
  text: string;
  date: string;
  imagesCount: string;
  onPress: () => void;
};
export const GalleryCard = ({
  backgroundImage,
  text,
  date,
  imagesCount,
  onPress,
}: GalleryCardProps) => {
  return (
    <Panel style={{ width: 300, height: 300 }} bgImage={backgroundImage}>
      <Wrapper onClick={onPress} fullHeight fullWidth>
        <VStack>
          <H4 textAlign="center" color="white">
            {text}
          </H4>
          <H5 textAlign="center" color="white">
            {date}
          </H5>
          <SmallText textAlign="center" color="white">
            {imagesCount}
          </SmallText>
        </VStack>
        <HStack>
          <RegularText color="white">{"voir les photos"}</RegularText>
          <FiArrowRight size={24} color="white"></FiArrowRight>
        </HStack>
      </Wrapper>
    </Panel>
  );
};

const Wrapper = styled(VStack)`
  gap: 6px;
  justify-content: space-between;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;
