import { useContext } from "react";
import styled from "styled-components";
import { ModalContext } from "./modal";
import { Option } from "types/global_types";

export type Props = {
  src: Option<string>;
  alt: string;
  objectFit?: "cover" | "contain";
};
/**
 * This component should be wrapped in a div element with specified width and height.
 */
export const ImageDisplay = ({ src, alt, objectFit = "cover" }: Props) => {
  const { handleModal } = useContext(ModalContext);

  const NoImage = require("assets/no_image.png");

  const handleClick = () => {
    if (!src) return;
    handleModal(<ImageZoomed src={src} alt={alt} />);
  };

  return (
    <ImageContainer>
      <Image
        src={src ?? NoImage}
        alt={alt}
        onClick={handleClick}
        objectFit={objectFit}
      />
    </ImageContainer>
  );
};

const ImageContainer = styled.div`
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Image = styled.img<{ objectFit: "cover" | "contain" }>`
  width: 100%;
  height: 100%;
  object-fit: ${({ objectFit }) => objectFit ?? "cover"};
  cursor: pointer;
`;

const ImageZoomed = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;
