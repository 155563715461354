import { toPng } from "html-to-image";
import download from "downloadjs";
import { FilledButton } from "components/ui/buttons";
import { HStack, VStack } from "components/ui/containers/stacks";
import { SmallText } from "components/ui/texts";
import { useState } from "react";
import { Cotisation, UserInfos } from "types/user.types";
import styled from "styled-components";
import { ColorsSystem } from "components/ui/colors";
import { SmallRadioButton } from "components/ui/inputs/radio_button";
import { CustomTextProps } from "components/ui/headings";
import { getCotisationFromLegacyCotisation } from "helpers/helpers";

const WIDTH = 421;
const HEIGHT = 596;

type TypeExtractFormProps = {
  member: UserInfos;
  cotisation: Cotisation;
};
export const RecuFiscalExtractModal = ({
  member,
  cotisation,
}: TypeExtractFormProps) => {
  const moment = require("moment");
  require("moment/locale/fr"); // Import French locale
  const nowInFrench = moment().locale("fr").format("D MMMM YYYY");

  const [donation, setDonation] = useState<Cotisation>(cotisation);
  const [orderNumber, setOrderNumber] = useState("2024-01");
  const [firstName, setFirstName] = useState(member.firstName);
  const [lastName, setLastName] = useState(member.lastName);

  const userFullAdress = `${member.civilAdress?.numberAdress} ${member.civilAdress?.adress} ${member.civilAdress?.zipcode} ${member.civilAdress?.city}`;
  const [userAdress, setUserAdress] = useState(userFullAdress);
  const [signature, setSignature] = useState("BL");
  const [paymentType, setPaymentType] = useState(cotisation.paymentType);
  const [recuFiscalDate, setRecuFiscalDate] = useState(nowInFrench);

  const node = document.getElementById("image-download");
  function downloadImage() {
    if (node) {
      toPng(node)
        .then((dataUrl) => {
          download(dataUrl, `recuFiscal-${orderNumber}-${member.lastName}.png`);
        })
        .catch((error) => {
          console.error("oops, something went wrong!", error);
        });
    }
  }

  function handleOrderNumber(event: React.ChangeEvent<HTMLInputElement>) {
    setOrderNumber(event.target.value);
  }

  function handleAdressChange(event: React.ChangeEvent<HTMLInputElement>) {
    setUserAdress(event.target.value);
  }

  function handleFirstNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFirstName(event.target.value);
  }

  function handleLastNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setLastName(event.target.value);
  }

  function handleDonationChange(key: string, value: any) {
    setDonation((prevDonation) => ({
      ...prevDonation,
      [key]: value,
    }));
  }

  return (
    <VStack
      alignItems="center"
      gap={30}
      style={{ width: WIDTH * 2, margin: 10 }}
    >
      <HStack justifyContent="space-between" gap={20}>
        <VStack gap={20}>
          <VStack gap={5}>
            <InputWrapper
              title="N° d'ordre"
              inputType="text"
              value={orderNumber}
              onChange={handleOrderNumber}
            />

            <InputWrapper
              title="Nom"
              inputType="text"
              value={lastName}
              onChange={handleLastNameChange}
            />
            <InputWrapper
              title="Prénom"
              inputType="text"
              value={firstName}
              onChange={handleFirstNameChange}
            />
            <InputWrapper
              title="Adresse"
              inputType="text"
              value={userAdress}
              onChange={handleAdressChange}
            />
            <InputWrapper
              title="Date"
              inputType="text"
              value={donation.date}
              onChange={(e) => handleDonationChange("date", e.target.value)}
            />
            <InputWrapper
              title="Montant"
              inputType="text"
              value={donation.value}
              onChange={(e) => handleDonationChange("value", e.target.value)}
            />
            <InputWrapper
              title="Montant en lettre"
              inputType="text"
              value={donation.valueInString}
              onChange={(e) =>
                handleDonationChange("valueInString", e.target.value)
              }
            />
          </VStack>

          <VStack gap={5}>
            <SmallText>type de paiement</SmallText>
            <SmallRadioButton
              text="espèces"
              onClick={() => setPaymentType("especes")}
              checked={paymentType === "especes"}
            />
            <SmallRadioButton
              text="chèque"
              onClick={() => setPaymentType("cheque")}
              checked={paymentType === "cheque"}
            />
            <SmallRadioButton
              text="virement"
              onClick={() => setPaymentType("virement")}
              checked={paymentType === "virement"}
            />
          </VStack>

          <VStack gap={5}>
            <InputWrapper
              title="Date du reçu"
              inputType="text"
              value={recuFiscalDate}
              onChange={(e) => setRecuFiscalDate(e.target.value)}
            />
          </VStack>

          <VStack gap={5}>
            <SmallText>signature</SmallText>
            <SmallRadioButton
              text="B.L. Pflimlin"
              onClick={() => setSignature("BL")}
              checked={signature === "BL"}
            />
            <SmallRadioButton
              text="H. Giaume"
              onClick={() => setSignature("H")}
              checked={signature === "H"}
            />
          </VStack>

          <div style={{ paddingTop: 20, alignItems: "center" }}>
            <FilledButton
              buttonSize="extraSmall"
              title="Télécharger le reçu fiscal"
              onClick={downloadImage}
            />
          </div>
        </VStack>

        <div
          id="image-download"
          style={{
            position: "relative",
            borderColor: "black",
            borderWidth: 1,
            borderStyle: "solid",
            width: WIDTH,
            height: HEIGHT,
            overflow: "hidden",
          }}
        >
          <img
            width={WIDTH}
            src={require("assets/recu_base.jpg")}
            alt="header"
          ></img>
          <div
            style={{
              position: "absolute",
              top: HEIGHT / 18,
              right: WIDTH / 8,
            }}
          >
            <StyledSmallText>{orderNumber}</StyledSmallText>
          </div>
          <div
            style={{
              position: "absolute",
              top: HEIGHT / 2.96,
              left: WIDTH / 7.5,
            }}
          >
            <StyledSmallText>{lastName + " " + firstName}</StyledSmallText>
          </div>
          <div
            style={{
              position: "absolute",
              top: HEIGHT / 2.68,
              left: WIDTH / 6,
            }}
          >
            <StyledSmallText>{userAdress}</StyledSmallText>
          </div>
          <div
            style={{
              position: "absolute",
              top: HEIGHT / 2.21,
              left: WIDTH / 5.4,
            }}
          >
            <StyledSmallText>{donation.value + " euros"}</StyledSmallText>
          </div>
          <div
            style={{
              position: "absolute",
              top: HEIGHT / 2.045,
              left: WIDTH / 2.35,
            }}
          >
            <StyledSmallText>{donation.valueInString}</StyledSmallText>
          </div>
          <div
            style={{
              position: "absolute",
              top: HEIGHT / 1.912,
              left: WIDTH / 3.9,
            }}
          >
            <StyledSmallText>{donation.date}</StyledSmallText>
          </div>
          {paymentType === "especes" && (
            <div
              style={{
                position: "absolute",
                top: HEIGHT / 1.37,
                left: WIDTH / 6,
              }}
            >
              <StyledSmallText>x</StyledSmallText>
            </div>
          )}
          {paymentType === "cheque" && (
            <div
              style={{
                position: "absolute",
                top: HEIGHT / 1.37,
                left: WIDTH / 2.53,
              }}
            >
              <StyledSmallText>x</StyledSmallText>
            </div>
          )}
          {paymentType === "virement" && (
            <div
              style={{
                position: "absolute",
                top: HEIGHT / 1.37,
                left: WIDTH / 1.93,
              }}
            >
              <StyledSmallText>x</StyledSmallText>
            </div>
          )}
          <div
            style={{
              position: "absolute",
              top: HEIGHT / 1.25,
              left: WIDTH / 6,
            }}
          >
            <StyledSmallText>{recuFiscalDate}</StyledSmallText>
          </div>
          {signature === "BL" && (
            <div
              style={{
                position: "absolute",
                top: HEIGHT / 1.26,
                left: WIDTH / 1.45,
              }}
            >
              <img
                width={50}
                src={require("assets/signature_bl.jpg")}
                alt="signature"
              ></img>
              <SignatureText>B.L. Pflimlin</SignatureText>
            </div>
          )}
          {signature === "H" && (
            <div
              style={{
                position: "absolute",
                top: HEIGHT / 1.27,
                left: WIDTH / 1.45,
              }}
            >
              <img
                width={40}
                src={require("assets/signature_h.jpg")}
                alt="signature"
              ></img>
              <SignatureText>H. Giaume</SignatureText>
            </div>
          )}
        </div>
      </HStack>
    </VStack>
  );
};

type InputWrapperProps = {
  title: string;
  inputType: "text" | "number";
  value: string | number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const InputWrapper = ({
  title,
  inputType,
  value,
  onChange,
}: InputWrapperProps) => {
  return (
    <VStack>
      <SmallText>{title}</SmallText>
      <HStack>
        <input type={inputType} value={value} onChange={onChange} />
      </HStack>
    </VStack>
  );
};

const StyledSmallText = styled.p<CustomTextProps>`
  overflow-wrap: no-wrap;
  font-size: 8px;
  line-height: 16px;
  font-weight: 300px;
  color: ${ColorsSystem.darkGrey};
  font-family: ${({ fontFamily }) => fontFamily ?? "Arial"};
  text-align: center;
  align-self: auto;
  margin: 0;
  padding: 0;
  white-space: nowrap;
`;

const SignatureText = styled.p`
  font-size: 8px;
  font-weight: 200px;
  font-family: Arial, sans-serif;
  color: ${({ color }) => color ?? ColorsSystem.darkGrey};
  text-align: center;
  align-self: auto;
`;
