import { BluePanelTitle, RegularText, SmallText } from "components/ui/texts";
import { useUserContext } from "contexts/user_context";
import { Reunion } from "types/reunions.types";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { FileUploadService } from "components/file_upload/application/FileUploadService";
import { HStack, VStack } from "components/ui/containers/stacks";
import { Panel } from "components/ui/cards";
import { TextInput } from "components/ui/inputs/text_input";
import { FilledButton } from "components/ui/buttons";
import { SPACE_BETWEEN_INPUT } from "components/ui/spacings";

export const ReunionForm = () => {
  const { currentUser, loading, setLoading } = useUserContext();
  if (!currentUser)
    return <>Vous devez être connecté pour accèder à ce formulaire</>;
  const initialValuesReunion: Reunion = {
    id: uuidv4(),
    title: "",
    galleryImages: [],
    userId: currentUser.id,
    creationDate: Date.now(),
    date: "",
  };
  const validationSchemaReunion = Yup.object().shape({
    title: Yup.string().required("Titre obligatoire"),
    date: Yup.string().required("Date obligatoire"),
  });

  const handleSubmit = async (values: Reunion, resetForm: () => void) => {
    const { title, date, id, userId, galleryImages } = values;

    try {
      const newReunion: Reunion = {
        title,
        userId: userId,
        date,
        creationDate: Date.now(),
        id,
        galleryImages: [],
      };
      console.log("newReunion", newReunion);
      // await createReunionDoc(newReunion);
      await FileUploadService.createReunion(newReunion);
      alert("Réunion créée avec succès!");
      resetForm();
    } catch (error: any) {
      console.log("error", error);
    }
  };

  return (
    <VStack>
      <Panel>
        <BluePanelTitle>CREATION REUNION</BluePanelTitle>
        {/* <SmallText color={"black"}>
          Renseigner les informations de la réunion
        </SmallText> */}
        <Formik
          initialValues={initialValuesReunion}
          onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
          validationSchema={validationSchemaReunion}
        >
          {({ dirty, values, errors, setFieldValue }) => {
            return (
              <Form>
                <VStack gap={30}>
                  {Object.values(errors)
                    .flatMap((x) =>
                      typeof x === "string" ? x : Object.values(x)
                    )
                    .map((e, i) => (
                      <RegularText color="red" key={`errnum${i}`}>
                        {(e || "").toString()}
                      </RegularText>
                    ))}

                  <VStack gap={10}>
                    <RegularText>Infos de base</RegularText>

                    <VStack gap={SPACE_BETWEEN_INPUT}>
                      <TextInput
                        id={"title"}
                        name={"title"}
                        type="text"
                        label={"Titre de la réunion"}
                        width="100%"
                      />
                      <TextInput
                        id={"date"}
                        name={"date"}
                        type="text"
                        label={"Date de la réunion"}
                        width="100%"
                      />
                    </VStack>
                  </VStack>
                  <VStack alignItems="center">
                    <FilledButton
                      title="Créer la réunion"
                      buttonSize="small"
                      type={"submit"}
                    />
                  </VStack>
                </VStack>
              </Form>
            );
          }}
        </Formik>
      </Panel>
    </VStack>
  );
};
