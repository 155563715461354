import React, {
  ReactNode,
  createContext,
  useState,
  useContext,
  useEffect,
} from "react";
import ReactDOM from "react-dom";

import { IoCloseSharp } from "react-icons/io5";
import styled from "styled-components";
import { VStack } from "./containers/stacks";
import { ColorsSystem } from "./colors";

interface ModalContextProps {
  children: ReactNode;
}

export const ModalContext = createContext<any>({});
const { Provider } = ModalContext;

export const ModalProvider = ({ children }: ModalContextProps) => {
  const { modal, handleModal, modalContent } = useModal();
  useEffect(() => {
    if (modal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modal]);
  return (
    <Provider value={{ modal, handleModal, modalContent }}>
      <Modal />
      {children}
    </Provider>
  );
};

export const useModal = () => {
  const [modal, setModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<string>("");
  const handleModal = (content?: string) => {
    if (content) {
      setModal(true);
      setModalContent(content);
    } else {
      setModal(false);
    }
  };
  return { modal, handleModal, modalContent };
};

export const Modal = () => {
  const { modalContent, handleModal, modal } = React.useContext(ModalContext);

  if (!modal) {
    return null;
  }
  return ReactDOM.createPortal(
    <Overlay>
      <ModalContainer>
        <IconContainer>
          <IoCloseSharp
            color={ColorsSystem.darkGrey}
            size={32}
            onClick={() => handleModal()}
          ></IoCloseSharp>
        </IconContainer>

        <div style={{ overflow: "auto", margin: "5px" }}>{modalContent}</div>
      </ModalContainer>
    </Overlay>,
    document.querySelector("#modal-root") as Element
  );
};

export const useModalContext = () => useContext(ModalContext);

const IconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  background-color: white;
  display: flex;
  align-self: flex-end;
  margin-top: 8px;
  margin-right: 4px;
`;

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
`;

const ModalContainer = styled(VStack)`
  position: relative;
  background: white;
  width: 100%;
  padding-top: ${24}px;
  padding-bottom: ${24}px;
  padding-left: ${32}px;
  padding-right: ${32}px;
  width: fit-content;
  overflow: hidden;
  overflow-y: hidden;
  min-width: 350px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  overflow-x: hidden;
  max-height: 90vh;
  border-radius: 8px;
  margin: 20px;
`;
