import { ChangeEvent, Ref, TextareaHTMLAttributes, forwardRef } from "react";
import styled from "styled-components";

import { Field, FieldProps } from "formik";
import { SharedInputProps } from "./input_config";
import { InputWrapperWithErrorMessage } from "./input_wrappers";
import { commonInputCSS } from "./text_input";

const TEXTAREA_ROWS = 3;
const TextareaContainer = styled.textarea`
  ${commonInputCSS};
  resize: none;
  height: initial;
`;

type Props = TextareaHTMLAttributes<HTMLTextAreaElement> &
  SharedInputProps & {
    value?: string;
    numberOfRows?: number;
  };

export const TextAreaInput = forwardRef(
  (
    { onValueChange, label, error, width, numberOfRows, ...props }: Props,
    ref: Ref<HTMLTextAreaElement> | null
  ) => {
    return (
      <Field name={props.name}>
        {({ field, form }: FieldProps) => {
          const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
            const { value } = event.currentTarget;
            if (!props.onChange && !onValueChange) {
              form.setFieldValue(field.name, value);
            } else {
              props.onChange?.(event);
              onValueChange?.(value);
            }
          };
          return (
            <InputWrapperWithErrorMessage
              error={error}
              label={label}
              width={width}
            >
              <TextareaContainer
                {...props}
                {...field}
                ref={ref}
                isValid={!error}
                rows={numberOfRows ?? TEXTAREA_ROWS}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                  handleChange(event)
                }
              />
            </InputWrapperWithErrorMessage>
          );
        }}
      </Field>
    );
  }
);
