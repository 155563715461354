import { useNavigate } from "react-router-dom";
import { VStack } from "components/ui/containers/stacks";
import { Presentation } from "components/home_page/presentation";
import { ColorsSystem } from "components/ui/colors";
import styled, { CSSProperties, css } from "styled-components";
import { H4 } from "components/ui/headings";
import { PageWrapper } from "components/ui/containers/page";
import { IoCameraSharp, IoChatboxEllipses, IoReader } from "react-icons/io5";
import { ImageDisplay } from "components/ui/image_display";

export const HomePage = () => {
  const navigation = useNavigate();
  return (
    <PageWrapper>
      <H4>Bienvenue sur le site de la Promotion Général Laurier</H4>
      <ImageContainer>
        <ImageDisplay
          src={require("assets/photo_groupe.jpg")}
          alt="image promotion"
          objectFit="cover"
        />
      </ImageContainer>
    </PageWrapper>
  );
};

type CardProps = {
  backgroundImage?: string;
  text: string;
  onPress: () => void;
};

type PanelProps = {
  padding?: CSSProperties["padding"];
  margin?: CSSProperties["margin"];
};

const ImageContainer = styled(VStack)`
  box-sizing: border-box;
  width: 80%;
  overflow: hidden;
  border-radius: 10px;
`;

const commonLinkIconStyle = css`
  color: ${ColorsSystem.powderBlueOne};
  &:hover {
    cursor: pointer;
  }
`;
const CameraIcon = styled(IoCameraSharp)`
  ${commonLinkIconStyle};
`;
const NewsIcon = styled(IoChatboxEllipses)`
  ${commonLinkIconStyle};
`;
const BulletinIcon = styled(IoReader)`
  ${commonLinkIconStyle};
`;

{
  /* <HStack alignItems="center" fullWidth justifyContent="space-around">
<VStack alignItems="center">
  <Card
    height={250}
    width={250}
    style={{
      cursor: "pointer",
    }}
    onClick={() => navigation("/reunionspromo")}
  >
    <NewsIcon size={42}></NewsIcon>
    <RegularText color={ColorsSystem.powderBlueOne}>
      Les dernières nouvelles
    </RegularText>
  </Card>
</VStack>
<VStack alignItems="center">
  <CameraIcon size={42}></CameraIcon>
  <RegularText color={ColorsSystem.powderBlueOne}>
    Revivre les réunions promotion
  </RegularText>
</VStack>
<VStack alignItems="center">
  <BulletinIcon size={42}></BulletinIcon>
  <RegularText color={ColorsSystem.powderBlueOne}>
    Le dernier bulletin en ligne
  </RegularText>
</VStack>
</HStack> */
}
