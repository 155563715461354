import React from "react";
import styled, { css } from "styled-components";
import { ColorsSystem } from "./colors";

export type CustomTextProps = {
  crop?: number;
  color?: string;
  textAlign?: React.CSSProperties["textAlign"];
  fontFamily?: React.CSSProperties["fontFamily"];
  alignSelf?: React.CSSProperties["alignSelf"];
  fontWeight?: React.CSSProperties["fontWeight"];
};

export type TextProps = CustomTextProps & {
  children?: React.ReactNode;
};

const commonHeadingStyle = css<{ crop?: number }>`
  ${(props) =>
    props.crop &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: ${props.crop};
    `}
`;

const StyledH1 = styled.h1<CustomTextProps>`
  ${commonHeadingStyle};
  font-size: 40px;
  line-height: 40px;
  font-family: ${({ fontFamily }) => fontFamily ?? "Montserrat, sans-serif"};
  color: ${({ color }) => color ?? ColorsSystem.darkGrey};
  text-align: ${({ textAlign }) => textAlign ?? "left"};
  align-self: ${({ alignSelf }) => alignSelf ?? "auto"};
  margin: 0;
  padding: 0;
`;

export const H1: React.FC<TextProps> = ({ children, ...props }) => {
  return <StyledH1 {...props}>{children}</StyledH1>;
};
const StyledH2 = styled.h2<CustomTextProps>`
  ${commonHeadingStyle};
  font-size: 3.2rem;
  line-height: 36px;
  font-family: ${({ fontFamily }) => fontFamily ?? "Montserrat, sans-serif"};
  color: ${({ color }) => color ?? ColorsSystem.darkGrey};
  text-align: ${({ textAlign }) => textAlign ?? "left"};
  align-self: ${({ alignSelf }) => alignSelf ?? "auto"};
  margin: 0;
  padding: 0;
`;
export const H2: React.FC<TextProps> = ({ children, ...props }) => {
  return <StyledH2 {...props}>{children}</StyledH2>;
};
const StyledH3 = styled.h3<CustomTextProps>`
  ${commonHeadingStyle};
  font-size: 2.8rem;
  font-family: ${({ fontFamily }) => fontFamily ?? "Montserrat, sans-serif"};
  color: ${({ color }) => color ?? ColorsSystem.darkGrey};
  text-align: ${({ textAlign }) => textAlign ?? "left"};
  align-self: ${({ alignSelf }) => alignSelf ?? "auto"};
  margin: 0;
  padding: 0;
`;
export const H3: React.FC<TextProps> = ({ children, ...props }) => {
  return <StyledH3 {...props}>{children}</StyledH3>;
};

const StyledH4 = styled.h4<CustomTextProps>`
  ${commonHeadingStyle};
  font-size: 2rem;
  font-family: ${({ fontFamily }) => fontFamily ?? "Montserrat, sans-serif"};
  color: ${({ color }) => color ?? ColorsSystem.darkGrey};
  text-align: ${({ textAlign }) => textAlign ?? "left"};
  align-self: ${({ alignSelf }) => alignSelf ?? "auto"};
  margin: 0;
  padding: 0;
  font-weight: 900;
`;
export const H4: React.FC<TextProps> = ({ children, ...props }) => {
  return <StyledH4 {...props}>{children}</StyledH4>;
};

const StyledH5 = styled.p<CustomTextProps>`
  ${commonHeadingStyle};
  font-size: 1.6rem;
  font-family: ${({ fontFamily }) => fontFamily ?? "Montserrat, sans-serif"};
  color: ${({ color }) => color ?? ColorsSystem.darkGrey};
  text-align: ${({ textAlign }) => textAlign ?? "left"};
  align-self: ${({ alignSelf }) => alignSelf ?? "auto"};
  margin: 0;
  padding: 0;
`;
export const H5: React.FC<TextProps> = ({ children, ...props }) => {
  return <StyledH5 {...props}>{children}</StyledH5>;
};
const StyledH6 = styled.h6<CustomTextProps>`
  ${commonHeadingStyle};
  font-size: 1.2rem;
  line-height: 12px;
  font-family: ${({ fontFamily }) => fontFamily ?? "Montserrat, sans-serif"};
  color: ${({ color }) => color ?? ColorsSystem.darkGrey};
  text-align: ${({ textAlign }) => textAlign ?? "left"};
  align-self: ${({ alignSelf }) => alignSelf ?? "auto"};
  margin: 0;
  padding: 0;
`;
export const H6: React.FC<TextProps> = ({ children, ...props }) => {
  return <StyledH6 {...props}>{children}</StyledH6>;
};

export const SectionTitle: React.FC<TextProps> = ({ children, ...props }) => (
  <StyledTitleSection {...props}>{children}</StyledTitleSection>
);

const StyledTitleSection = styled(H5)<CustomTextProps>`
  ${commonHeadingStyle};
  font-style: italic;
  color: ${ColorsSystem.blue};
`;
