import React from "react";
import { IconType } from "react-icons/lib";
import { ColorsSystem } from "./colors";
import { getCSSUnit } from "./layouts_helpers";

const DefaultIconSize = 24;

export const Icon: React.FC<{
  color?: string;
  size?: number;
  Component: IconType;
  onClick?: () => void;
  disabled?: boolean;
}> = ({ onClick, Component, color, size, disabled }) => {
  return (
    <Component
      onClick={disabled ? undefined : onClick}
      color={disabled ? ColorsSystem.mediumGrey : color}
      size={getCSSUnit(size ?? DefaultIconSize)}
    />
  );
};
