import { useContext } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import {
  SPACE_BETWEEN_STACK,
  SPACE_BETWEEN_INPUT,
} from "components/ui/spacings";
import { VStack } from "components/ui/containers/stacks";
import { UserInfos } from "types/user.types";
import { updatingUserDoc } from "utils/firebase/firebase.user";
import { ModalContext } from "components/ui/modal";
import { TextInput } from "components/ui/inputs/text_input";
import { FilledButton } from "components/ui/buttons";
import { UserContext } from "contexts/user_context";
import { Option } from "types/global_types";

export const UpdateUserContactsForm = ({ user }: { user: UserInfos }) => {
  const { handleModal } = useContext(ModalContext);
  const { setCurrentUser } = useContext(UserContext);

  const initialValues: Partial<UserInfos> = {
    email: user.email || "",
    landLineNumber: user.landLineNumber || "",
    phoneNumber: user.phoneNumber || "",
  };
  const validationSchema = Yup.object().shape({
    landLineNumber: Yup.string(),
    phoneNumber: Yup.string(),
    email: Yup.string().email("Invalid email address"),
  });

  const handleUpdate = async (values: Partial<UserInfos>) => {
    const updatedData = {
      landLineNumber: values.landLineNumber,
      phoneNumber: values.phoneNumber,
    };

    try {
      if (user && user.id) {
        const updatedUser = await updatingUserDoc(user.id, updatedData);

        setCurrentUser(updatedUser as Option<Partial<UserInfos>>);
        handleModal();
      } else {
        throw new Error("User ID is undefined.");
      }
    } catch (error) {
      console.error("Error updating user:", error);
      alert("Oops, something went wrong.");
    }
  };

  return (
    <VStack alignItems="center">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleUpdate}
      >
        {({ errors, touched, values }) => {
          return (
            <Form>
              <VStack gap={SPACE_BETWEEN_STACK} alignItems="center">
                <VStack gap={SPACE_BETWEEN_INPUT}>
                  <TextInput
                    value={values.landLineNumber}
                    id={"landLineNumber"}
                    name={"landLineNumber"}
                    type="text"
                    label={"Numéro de ligne fixe"}
                    error={errors.landLineNumber}
                  />
                </VStack>
                <VStack gap={SPACE_BETWEEN_INPUT}>
                  <TextInput
                    value={values.phoneNumber}
                    id={"phoneNumber"}
                    name={"phoneNumber"}
                    type="text"
                    label={"Numéro de portable"}
                    error={errors.phoneNumber}
                  />
                </VStack>
                <div>
                  <FilledButton
                    title="Sauvegarder"
                    buttonSize="small"
                    type="submit"
                  />
                </div>
              </VStack>
            </Form>
          );
        }}
      </Formik>
    </VStack>
  );
};
