import headerImage from "../../assets/header-couleur.jpg";
// import headerImage from "../../assets/header.png";
import { VStack } from "./containers/stacks";

export const Header = () => {
  return (
    <VStack
      style={{
        width: "100%",
      }}
    >
      <img src={headerImage} alt="header"></img>
    </VStack>
  );
};
