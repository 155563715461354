import React, { useContext } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import {
  SPACE_BETWEEN_STACK,
  SPACE_BETWEEN_INPUT,
  spacing,
} from "components/ui/spacings";
import { HStack, VStack } from "components/ui/containers/stacks";
import { UserInfos } from "types/user.types";
import { updatingUserDoc } from "utils/firebase/firebase.user";
import { ModalContext } from "components/ui/modal";
import { TextInput } from "components/ui/inputs/text_input";
import { FilledButton } from "components/ui/buttons";
import { UserContext } from "contexts/user_context";
import { Option } from "types/global_types";
import { RegularText } from "components/ui/texts";
import { InputGroup } from "components/ui/inputs/input_wrappers";

export const UpdateUserAdressesForm = ({ user }: { user: UserInfos }) => {
  const { handleModal } = useContext(ModalContext);
  const { setCurrentUser } = useContext(UserContext);

  const initialValues: Partial<UserInfos> = {
    civilAdress: user.civilAdress || {
      numberAdress: "",
      adress: "",
      zipcode: "",
      city: "",
      country: "",
    },
    permanentAdress: user.permanentAdress || {
      numberAdress: "",
      adress: "",
      zipcode: "",
      city: "",
      country: "",
    },
  };
  const validationSchema = Yup.object().shape({
    permanentAdress: Yup.object().shape({
      numberAdress: Yup.number(),
      adress: Yup.string(),
      zipcode: Yup.number(),
      city: Yup.string(),
      country: Yup.string(),
    }),
    civilAdress: Yup.object().shape({
      numberAdress: Yup.number(),
      adress: Yup.string(),
      zipcode: Yup.number(),
      city: Yup.string(),
      country: Yup.string(),
    }),
  });

  const handleUpdate = async (values: Partial<UserInfos>) => {
    const updatedData = {
      permanentAdress: values.permanentAdress,
      civilAdress: values.civilAdress,
    };

    try {
      if (user && user.id) {
        const updatedUser = await updatingUserDoc(user.id, updatedData);

        setCurrentUser(updatedUser as Option<Partial<UserInfos>>);
        handleModal();
      } else {
        throw new Error("User ID is undefined.");
      }
    } catch (error) {
      console.error("Error updating user:", error);
      alert("Oops, something went wrong.");
    }
  };

  return (
    <VStack alignItems="center" style={{ width: "100%" }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleUpdate}
      >
        {({ errors, touched, values }) => {
          return (
            <Form>
              <VStack
                gap={SPACE_BETWEEN_STACK}
                alignItems="center"
                style={{ width: "100%", marginTop: 20, marginBottom: 20 }}
              >
                <HStack gap={spacing.larger} alignItems="center">
                  <InputGroup>
                    <RegularText>Adresse Civile</RegularText>
                    <TextInput
                      id={"civilAdress.numberAdress"}
                      name={"civilAdress.numberAdress"}
                      type="text"
                      label={"Numéro de rue"}
                      error={errors.civilAdress}
                    />
                    <TextInput
                      id={"civilAdress.adress"}
                      name={"civilAdress.adress"}
                      type="text"
                      label={"Rue"}
                      error={errors.civilAdress}
                    />
                    <TextInput
                      id={"civilAdress.zipcode"}
                      name={"civilAdress.zipcode"}
                      type="text"
                      label={"Code postal"}
                      error={errors.civilAdress}
                    />
                    <TextInput
                      id={"civilAdress.city"}
                      name={"civilAdress.city"}
                      type="text"
                      label={"Ville"}
                      error={errors.civilAdress}
                    />
                    <TextInput
                      id={"civilAdress.country"}
                      name={"civilAdress.country"}
                      type="text"
                      label={"Pays"}
                      error={errors.civilAdress}
                    />
                  </InputGroup>
                  <InputGroup>
                    <RegularText>Adresse Permanente</RegularText>
                    <TextInput
                      id={"permanentAdress.numberAdress"}
                      name={"permanentAdress.numberAdress"}
                      type="text"
                      label={"Numéro de rue"}
                      width="100%"
                    />
                    <TextInput
                      id={"permanentAdress.adress"}
                      name={"permanentAdress.adress"}
                      type="text"
                      label={"Rue"}
                      width="100%"
                    />
                    <TextInput
                      id={"permanentAdress.zipcode"}
                      name={"permanentAdress.zipcode"}
                      type="text"
                      label={"Code postal"}
                      width="100%"
                    />
                    <TextInput
                      id={"permanentAdress.city"}
                      name={"permanentAdress.city"}
                      type="text"
                      label={"Ville"}
                      width="100%"
                    />
                    <TextInput
                      id={"permanentAdress.country"}
                      name={"permanentAdress.country"}
                      type="text"
                      label={"Pays"}
                      width="100%"
                    />
                  </InputGroup>
                </HStack>
                <div>
                  <FilledButton
                    title="Sauvegarder"
                    buttonSize="small"
                    type="submit"
                  />
                </div>
              </VStack>
            </Form>
          );
        }}
      </Formik>
    </VStack>
  );
};
