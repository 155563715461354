import { useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import { signInAuthUserWithEmailAndPassword } from "../../utils/firebase/firebase.utils";
import { useUserContext } from "../../contexts/user_context";

import { Formik, Form, Field, FieldProps } from "formik";
import { FiEye, FiEyeOff } from "react-icons/fi";
import * as Yup from "yup";
import { VStack } from "components/ui/containers/stacks";
import {
  SPACE_BETWEEN_INPUT,
  SPACE_BETWEEN_STACK,
  SPACE_BETWEEN_TEXT,
} from "components/ui/spacings";
import { Panel } from "components/ui/cards";
import { Label, SmallText } from "components/ui/texts";
import { H4 } from "components/ui/headings";
import { ColorsSystem } from "components/ui/colors";
import styled from "styled-components";
import { TextInput } from "components/ui/inputs/text_input";
import { FilledButton, TextButton } from "components/ui/buttons";
import { ModalContext } from "components/ui/modal";
import { PasswordResetModal } from "./forgotten_password";
import { IconInput } from "components/ui/inputs/icon_input";

const defaultFormFields = {
  email: "",
  password: "",
};

export const SignInForm = () => {
  const { currentUser } = useUserContext();
  const { handleModal } = useContext(ModalContext);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [formFields, setFormFields] = useState(defaultFormFields);
  const { email, password } = formFields;

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email address"),
    password: Yup.string().required("obligatoire"),
  });

  const handleSubmit = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    // event.preventDefault();

    try {
      await signInAuthUserWithEmailAndPassword(email, password);
      resetFormFields();
    } catch (error: any) {
      console.error("error");
      switch (error.code) {
        case "auth/wrong-password":
          alert("mot de passe incorrect pour cet email");
          break;
        case "auth/user-not-found":
          alert("email inconnu");
          break;
        default:
          console.log("error", error);
          break;
      }
    }
  };

  // const handlePasswordReset = () => {
  //   if (email) {
  //     resetPassword(email);
  //   } else {
  //     alert("Veuillez entrer une adresse email valide.");
  //   }
  // };

  const handleOpenPasswordResetModal = () => {
    handleModal(<PasswordResetModal />);
  };

  return (
    <>
      {currentUser ? (
        <Navigate to={"/profile"}></Navigate>
      ) : (
        <SignInFormWrapper>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ setFieldValue, dirty, values }) => {
              return (
                <Form>
                  <Panel>
                    <Wrapper gap={SPACE_BETWEEN_STACK}>
                      <VStack gap={SPACE_BETWEEN_TEXT}>
                        <H4 color={ColorsSystem.blue}>Bonjour !</H4>
                        <SmallText>
                          Connectez-vous pour consulter le site
                        </SmallText>
                      </VStack>
                      <VStack gap={SPACE_BETWEEN_INPUT}>
                        <Label label={"Email"} />
                        <TextInput
                          required
                          type="email"
                          name="email"
                          value={email}
                          id={"email"}
                        />
                        {/* <Label label={"Mot de passe"} /> */}
                        {/* <TextInput
                          required
                          type="password"
                          name="password"
                          value={password}
                          id={"password"}
                        /> */}
                        <Field id="password" name="password">
                          {({ field, form }: FieldProps) => {
                            const errorLabel =
                              form.errors.password && form.touched.password
                                ? form.errors.password
                                : null;

                            return (
                              <IconInput
                                error={errorLabel as string}
                                {...field}
                                label={"Mot de passe"}
                                Icon={showPassword ? FiEyeOff : FiEye}
                                inputType={showPassword ? "text" : "password"}
                                onIconClick={() =>
                                  setShowPassword(!showPassword)
                                }
                              />
                            );
                          }}
                        </Field>
                      </VStack>
                      <ButtonWrapper>
                        <FilledButton
                          title=" Je me connecte"
                          buttonSize="medium"
                          type="submit"
                        />
                        <TextButton
                          title="Mot de passe oublié ?"
                          buttonSize="extraSmall"
                          onClick={() => handleOpenPasswordResetModal()}
                        />
                      </ButtonWrapper>
                    </Wrapper>
                  </Panel>
                </Form>
              );
            }}
          </Formik>
        </SignInFormWrapper>
      )}
    </>
  );
};

const Wrapper = styled(VStack)`
  padding: 10px 40px 20px 20px;
`;

const ButtonWrapper = styled(VStack)`
  padding-top: 20px;
  align-items: center;
  gap: 10px;
`;

{
  /* <form onSubmit={handleSubmit}>
            <TextInput
            label="Email"
            required
            type="email"
            name="email"
            value={email}
            onChange={handleChange}
            id={""}
            />
            <TextInput
            label="Mot de passe"
            required
            type="password"
            name="password"
            value={password}
            onChange={handleChange}
            id={""}
            />
            <RegularButton type="submit">Je me connecte</RegularButton>
          </form> */
}

const SignInFormWrapper = styled(VStack)`
  align-items: center;
  margin-top: 50px;
`;
