import React, { ButtonHTMLAttributes } from "react";
import { IconType } from "react-icons/lib";
import { css } from "styled-components";
import { ColorHex } from "../colors";

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  alignSelf?: React.CSSProperties["alignSelf"];
  buttonSize: "small" | "medium" | "large" | "extraSmall";
  color?: ColorHex;
  error?: boolean;
  href?: string;
  leftIcon?: IconType;
  loading?: boolean;
  rightIcon?: IconType;
  title?: string;
  width?: React.CSSProperties["width"];
  centered?: boolean;
};

export const commonButtonStyle = css`
  box-sizing: border-box;
  cursor: pointer;
  // font-family: Branding-Bold;
  display: flex;
  align-items: center;
  gap: 4px;
  border: 0px;
  border-radius: 500px;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
  align-items: center;
`;
export const largeStyle = css`
  height: 56px;
  padding: 16px 32px;
  font-size: 20px;
  // line-height: 24px;
`;
export const mediumStyle = css`
  height: 44px;
  padding: 16px 24px;
  font-size: 18px;
  // line-height: 24px;
`;
export const smallStyle = css`
  height: 32px;
  padding: 16px 16px;
  font-size: 16px;
  line-height: 20px;
`;

export const extraSmallStyle = css`
  height: 24px;
  padding: 8px 16px;
  font-size: 12px;
  line-height: 16px;
`;
