import React, { Dispatch, SetStateAction } from "react";
import { User, onAuthStateChanged } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserDocFromAuth,
  onAuthStateChangedListener,
} from "../utils/firebase/firebase.utils";
import { UserInfos } from "types/user.types";
import { Option } from "types/global_types";

export const UserContext = createContext<{
  currentUser: Option<UserInfos>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setCurrentUser: Dispatch<SetStateAction<Option<Partial<UserInfos>>>>;
}>({
  loading: true,
  currentUser: null,
  setCurrentUser: () => null,
  setLoading: () => false,
});

export const useUserContext = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }: any) => {
  const [currentUser, setCurrentUser] =
    useState<Option<Partial<UserInfos>>>(null);
  const [loading, setLoading] = useState<Boolean>(true);
  const [isCreating, setIsCreating] = useState<Boolean>(false);
  const value = {
    currentUser,
    setCurrentUser,
    loading,
    setLoading,
    setIsCreating,
  };
  useEffect(() => {
    const unsubscribe = onAuthStateChangedListener(async (user) => {
      setLoading(true);
      if (user && !isCreating) {
        const userData = await createUserDocFromAuth(user);
        if (userData) {
          setCurrentUser(userData);
        }
      }
      setLoading(false);
    });
    return unsubscribe;
  }, [isCreating]);

  return (
    <UserContext.Provider value={value as any}>{children}</UserContext.Provider>
  );
};
