import React from "react";
import styled, { css } from "styled-components";
import { H5 } from "../headings";
import { VStack } from "../containers/stacks";
import { RegularText } from "../texts";
import { device } from "../global_styles";
import { spacing } from "../spacings";
import { ColorsSystem } from "../colors";

export type Props<T = any> = {
  label?: string;
  error?: string;
  width?: string;
  children: React.ReactNode;
  as?: string | React.ComponentType<T>;
};

const Container = styled(VStack)<{ isValid: boolean; width?: string }>`
  width: ${({ width }) => width || "clamp(256px, 100%, 448px)"};
  color: ${({ isValid }) =>
    isValid ? ColorsSystem.powderBlueTwo : ColorsSystem.powderBlueOne};
  ${({ isValid }) =>
    isValid &&
    css`
      :focus-within {
        color: ${ColorsSystem.powderBlueTwo};
      }
    `}
  @media only screen and ${device.tabletPortrait} {
    width: ${({ width }) => width || "100%"};
  }
`;
export const InputWrapper: React.FC<Props> = ({
  label,
  children,
  error,
  width,
  as = "label",
}) => (
  <Container
    tabIndex="-1"
    isValid={!error}
    fullWidth
    gap={8}
    as={as}
    width={width}
  >
    {label && (
      <H5 color={error ? ColorsSystem.red : ColorsSystem.mediumGrey}>
        {label}
      </H5>
    )}
    {children}
  </Container>
);

export const InputWrapperWithErrorMessage: React.FC<Props> = ({
  children,
  ...props
}) => (
  <InputWrapper {...props}>
    <VStack style={{ position: "relative" }} fullWidth gap={4}>
      {children}
      <RegularText color={ColorsSystem.red}>{props.error}</RegularText>
    </VStack>
  </InputWrapper>
);

export const InputGroup = styled(VStack)`
  gap: ${spacing.small}px;
`;
