import React from "react";
import styled, { css } from "styled-components";
import { FiLoader } from "react-icons/fi";

import {
  largeStyle,
  mediumStyle,
  smallStyle,
  commonButtonStyle,
  ButtonProps,
} from "./config";
import { ColorsSystem } from "../colors";
import { Icon } from "../icon";
import { getCSSUnit } from "../layouts_helpers";

export const TextButton: React.FC<ButtonProps> = ({
  buttonSize = "medium",
  title = "",
  disabled = false,
  loading = false,
  leftIcon = undefined,
  rightIcon = undefined,
  error = false,
  href,
  ...props
}) => {
  return (
    <TextContainer
      {...(href
        ? ({
            as: "a",
            href,
          } as any)
        : {})}
      type={props.type ?? "button"}
      disabled={disabled}
      buttonSize={buttonSize}
      error={error}
      {...props}
    >
      {loading ? (
        <Icon Component={FiLoader} size={buttonSize === "small" ? 16 : 24} />
      ) : (
        <>
          {leftIcon && (
            <Icon
              Component={leftIcon}
              size={buttonSize === "small" ? 16 : 24}
            />
          )}
          {title && title}
          {rightIcon && (
            <Icon
              Component={rightIcon}
              size={buttonSize === "small" ? 16 : 24}
            />
          )}
        </>
      )}
    </TextContainer>
  );
};

const TextContainer = styled.button<ButtonProps>`
  ${commonButtonStyle};
  background-color: transparent;
  color: ${ColorsSystem.blue};
  width: ${({ width }) => (width ? getCSSUnit(width) : undefined)};
  ${({ buttonSize }) =>
    buttonSize === "large"
      ? largeStyle
      : buttonSize === "medium"
      ? mediumStyle
      : smallStyle};
  &:hover {
    color: ${ColorsSystem.primary_hover};
    color: ${ColorsSystem.primary_hover};
  }
  &:active {
    color: ${ColorsSystem.primary_active};
  }
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${ColorsSystem.lightGrey};
      cursor: not-allowed;
    `};
  ${({ error }) =>
    error &&
    css`
      color: ${ColorsSystem.red};
      &:hover {
        color: ${ColorsSystem.red};
      }
      &:active {
        color: ${ColorsSystem.red};
      }
    `};
  padding-left: 0;
  padding-right: 0;
`;
