import { useState } from "react";
import styled from "styled-components";
import ReactSimplyCarousel from "react-simply-carousel";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { RegularText } from "components/ui/texts";

export type GalleryContentImages = {
  images: string[];
  title: string;
};

export const ImagesCarousel = ({ images, title }: GalleryContentImages) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  return (
    <div style={{ paddingBottom: 20 }}>
      <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={1}
        itemsToScroll={1}
        forwardBtnProps={{
          style: {
            alignSelf: "center",
            background: "black",
            border: "none",
            borderRadius: "50%",
            color: "white",
            cursor: "pointer",
            fontSize: "20px",
            height: 30,
            textAlign: "center",
            width: 30,
            marginLeft: 10,
          },
          children: (
            <>
              <FiArrowRight size={16}></FiArrowRight>
            </>
          ),
        }}
        backwardBtnProps={{
          style: {
            alignSelf: "center",
            background: "black",
            border: "none",
            borderRadius: "50%",
            color: "white",
            cursor: "pointer",
            fontSize: "20px",
            textAlign: "center",
            height: 30,
            width: 30,
            marginRight: 10,
          },
          children: (
            <span>
              <FiArrowLeft size={16}></FiArrowLeft>
            </span>
          ),
        }}
        responsiveProps={[
          {
            itemsToShow: 1,
            itemsToScroll: 1,
            minWidth: 1200,
          },
        ]}
        speed={400}
        easing="linear"
        centerMode
      >
        {images.map((image) => {
          return (
            <>
              <div key={image}>
                <img
                  src={image}
                  alt={""}
                  width={500}
                  height={500}
                  style={{
                    objectFit: "contain",
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                ></img>
              </div>
            </>
          );
        })}
      </ReactSimplyCarousel>
    </div>
  );
};

const CarouselImageTitle = styled(RegularText)`
  margin-bottom: 10px;
  text-align: center;
`;
