import { Routes, Route } from "react-router-dom";
import { Profile } from "./routes/profile/profile";
import { Reunions } from "./routes/reunions_gallery/reunion_gallery";
import { Members } from "./routes/members_list/members_list";
import { ContactPage } from "./components/contacts_page/contact_page";
import { AdminSpace } from "./routes/admin_space/admin_space";
import { useUserContext } from "./contexts/user_context";
import { NavBar } from "./routes/navigation_bar/nav_bar";
import { PrivateRoute } from "./routes/private_route/private_route";
import { Authentication } from "./routes/authentication/authentication";
import { HomePage } from "./routes/home/home_page";
import Footer from "components/ui/footer";
import { Bulletin } from "routes/bulletin/bulletin";
import { useEffect, useState } from "react";
import Loader from "components/ui/loaders/loader";
import { PromoPresentation } from "routes/promo_presentation/promo_presentation";

const PageNotFound = () => {
  return <></>;
};
const App = () => {
  const { currentUser, isLoading } = useUserContext();

  const [loadingUser, setLoadingUser] = useState(true);

  useEffect(() => {
    setLoadingUser(isLoading);
  }, [isLoading]);

  return (
    <>
      {/* {loadingUser ? (
        <Loader />
      ) : ( */}
      <Routes>
        <Route path="/" element={<NavBar />}>
          <Route index element={<HomePage />} />
          <Route
            path={"profile"}
            element={<PrivateRoute component={Profile} />}
          />
          <Route
            path={"presentation_promo"}
            element={<PrivateRoute component={PromoPresentation} />}
          />
          <Route
            path={"reunionspromo"}
            element={<PrivateRoute component={Reunions} />}
          />
          <Route
            path={"membres"}
            element={<PrivateRoute component={Members} />}
          />

          <Route path="contacts" element={<ContactPage />} />
          <Route path="auth" element={<Authentication />} />
          <Route
            path="admin"
            element={<PrivateRoute component={AdminSpace} />}
          />
          {/* <Route
            path="bulletin"
            element={<PrivateRoute component={Bulletin} />}
          /> */}
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
      {/* )} */}
      {/* <Footer></Footer> */}
    </>
  );
};

export default App;
