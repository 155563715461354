import React, { useContext } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import {
  SPACE_BETWEEN_STACK,
  SPACE_BETWEEN_INPUT,
} from "components/ui/spacings";
import { VStack } from "components/ui/containers/stacks";
import { UserInfos } from "types/user.types";
import { updatingUserDoc } from "utils/firebase/firebase.user";
import { ModalContext } from "components/ui/modal";
import { TextInput } from "components/ui/inputs/text_input";
import { FilledButton } from "components/ui/buttons";
import { UserContext } from "contexts/user_context";
import { Option } from "types/global_types";
import { TextAreaInput } from "components/ui/inputs/text_area_input";

export const UpdateUserInfosForm = ({ user }: { user: UserInfos }) => {
  const { handleModal } = useContext(ModalContext);
  const { setCurrentUser } = useContext(UserContext);

  const initialValues: Partial<UserInfos> = {
    moreInfos: user.moreInfos || "",
  };
  const validationSchema = Yup.object().shape({
    moreInfos: Yup.string(),
  });

  const handleUpdate = async (values: Partial<UserInfos>) => {
    const updatedData = {
      moreInfos: values.moreInfos,
    };

    try {
      if (user && user.id) {
        const updatedUser = await updatingUserDoc(user.id, updatedData);

        setCurrentUser(updatedUser as Option<Partial<UserInfos>>);
        handleModal();
      } else {
        throw new Error("User ID is undefined.");
      }
    } catch (error) {
      console.error("Error updating user:", error);
      alert("Oops, something went wrong.");
    }
  };

  return (
    <VStack alignItems="center">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleUpdate}
      >
        {({ errors, touched, values }) => {
          return (
            <Form>
              <VStack gap={SPACE_BETWEEN_STACK} alignItems="center">
                <VStack gap={SPACE_BETWEEN_INPUT}>
                  <TextAreaInput
                    value={values.moreInfos}
                    id={"moreInfos"}
                    name={"moreInfos"}
                    label={"Infos supplémentaires"}
                    error={errors.moreInfos}
                    width="100%"
                  />
                </VStack>
                <div>
                  <FilledButton
                    title="Sauvegarder"
                    buttonSize="small"
                    type="submit"
                  />
                </div>
              </VStack>
            </Form>
          );
        }}
      </Formik>
    </VStack>
  );
};
