import { useContext } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import {
  SPACE_BETWEEN_STACK,
  SPACE_BETWEEN_INPUT,
} from "components/ui/spacings";
import { HStack, VStack } from "components/ui/containers/stacks";
import { MaritalStatus, UserInfos } from "types/user.types";
import { updatingUserDoc } from "utils/firebase/firebase.user";
import { ModalContext } from "components/ui/modal";
import { TextInput } from "components/ui/inputs/text_input";
import { FilledButton } from "components/ui/buttons";
import { UserContext } from "contexts/user_context";
import { Option } from "types/global_types";
import { RegularText } from "components/ui/texts";
import { AddChildren } from "routes/admin_space/add_child";
import { OptionType, SelectInput } from "components/ui/inputs/select_input";

export const UpdateUserFamilyForm = ({ user }: { user: UserInfos }) => {
  const { handleModal } = useContext(ModalContext);
  const { setCurrentUser } = useContext(UserContext);

  const initialValues: Partial<UserInfos> = {
    maritalSituation: user.maritalSituation || {},
    children: user.children || [],
  };
  const validationSchema = Yup.object().shape({
    landLineNumber: Yup.string(),
    phoneNumber: Yup.string(),
    email: Yup.string().email("Invalid email address"),
  });

  const handleUpdate = async (values: Partial<UserInfos>) => {
    const updatedData = {
      maritalSituation: {
        maritalStatus: values.maritalSituation?.maritalStatus,
        wifeName: values.maritalSituation?.wifeName,
        childrenCount: values.children?.length || 0,
      },
      children: values.children,
    };

    try {
      if (user && user.id) {
        const updatedUser = await updatingUserDoc(user.id, updatedData);

        setCurrentUser(updatedUser as Option<Partial<UserInfos>>);
        handleModal();
      } else {
        throw new Error("User ID is undefined.");
      }
    } catch (error) {
      console.error("Error updating user:", error);
      alert("Oops, something went wrong.");
    }
  };

  const getMemberMaritalStatus = (maritalStatus: MaritalStatus) => {
    if (maritalStatus[0] === "M") {
      return "Marié";
    } else if (maritalStatus[0] === "C") {
      return "Célibataire";
    } else if (maritalStatus[0] === "R") {
      return "Remarié";
    } else if (maritalStatus[0] === "D") {
      return "Divorcé";
    } else if (maritalStatus[0] === "V") {
      return "Veuf";
    }
    return maritalStatus;
  };

  const maritaStatusOptions: OptionType[] = [
    {
      value: "Célibataire",
      label: "Célibataire",
    },
    {
      value: "Marié",
      label: "Marié",
    },
    {
      value: "Divorcé",
      label: "Divorcé",
    },
    {
      value: "Veuf",
      label: "Veuf",
    },
    {
      value: "Remarié",
      label: "Remarié",
    },
  ];

  return (
    <VStack alignItems="center">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleUpdate}
      >
        {({ errors, touched, values, setFieldValue }) => {
          return (
            <Form>
              <VStack
                gap={SPACE_BETWEEN_STACK}
                style={{ minWidth: 300, minHeight: 300 }}
                justifyContent="space-between"
              >
                <HStack gap={30}>
                  <VStack gap={SPACE_BETWEEN_INPUT}>
                    <RegularText>Situation maritale</RegularText>
                    <SelectInput
                      defaultValue={maritaStatusOptions.find(
                        (option) =>
                          option.value ===
                          getMemberMaritalStatus(
                            values.maritalSituation
                              ?.maritalStatus as MaritalStatus
                          )
                      )}
                      label="Statut marital"
                      options={maritaStatusOptions}
                      onChange={(option) => {
                        setFieldValue(
                          "maritalSituation.maritalStatus",
                          option.value
                        );
                      }}
                    />
                    <TextInput
                      id={"maritalSituation.wifeName"}
                      name={"maritalSituation.wifeName"}
                      type="text"
                      label={"Nom de l'épouse"}
                      error={errors.maritalSituation}
                    />
                  </VStack>

                  <VStack alignItems="left" gap={10}>
                    <RegularText>Enfants</RegularText>
                    <AddChildren
                      childrenValues={values.children || []}
                      name={"children"}
                      id={"children"}
                    />
                  </VStack>
                </HStack>
                <VStack alignItems="center">
                  <FilledButton
                    title="Sauvegarder"
                    buttonSize="small"
                    type="submit"
                  />
                </VStack>
              </VStack>
            </Form>
          );
        }}
      </Formik>
    </VStack>
  );
};
