import { VStack } from "components/ui/containers/stacks";
import { CreateMemberForm, UserInfosAndPassword } from "./create_member_form";
import { BluePanelTitle } from "components/ui/texts";
import { useContext } from "react";
import { ModalContext } from "components/ui/modal";
import {
  createAuthUserWithEmailAndPasswordByAdmin,
  createUserDocFromAuth,
} from "utils/firebase/firebase.utils";

export const CreateMemberModal = () => {
  const { handleModal } = useContext(ModalContext);

  const handleSubmit = async (values: UserInfosAndPassword) => {
    const {
      lastName,
      firstName,
      login,
      password,
      confirmPassword,
      email,
      landLineNumber,
      phoneNumber,
      civilAdress,
      region,
      diploma,
      birthdate,
      grade,
      DATECEN,
      DATE1LCL,
      DATE2CL,
      brigade,
      army,
      originArmy,
      maritalSituation,
      children,
      permanentAdress,
      repReunion,
      promotion,
      cotisations,
      isDeceased,
      deceasedData,
    } = values;
    if (password !== confirmPassword) {
      alert("Attention! Vos mots de passe doivent être identiques");
      return;
    }

    if (password.length < 6) {
      alert("Le mot de passe doit contenir au moins 6 caractères");
      return;
    }

    try {
      const { user } = await createAuthUserWithEmailAndPasswordByAdmin(
        email,
        password
      );

      console.log("got user", user);

      await createUserDocFromAuth(user, {
        lastName,
        firstName,
        login,
        email,
        landLineNumber,
        phoneNumber,
        civilAdress,
        region,
        diploma,
        birthdate,
        grade,
        DATECEN,
        DATE1LCL,
        DATE2CL,
        brigade,
        army,
        originArmy,
        maritalSituation,
        children,
        permanentAdress,
        repReunion,
        promotion,
        cotisations,
        isDeceased,
        deceasedData,
      });
      alert("Utilisateur créé avec succès!");
      handleModal();
    } catch (error: any) {
      if (error.code === "auth/email-already-in-use")
        alert("email déjà utilisé");
      console.log("error", error);
    }
  };
  return (
    <VStack>
      <BluePanelTitle>CREATION MEMBRE PROMOTION</BluePanelTitle>
      <CreateMemberForm handleSubmit={handleSubmit} />
    </VStack>
  );
};
