import React from "react";
import styled, { css } from "styled-components";
import { getCSSUnit } from "../layouts_helpers";
import { ColorsSystem } from "../colors";
import { RegularText, SmallText } from "../texts";

const Container = styled.div<{ disabled?: boolean }>`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

const BoxContainer = styled.div<{
  size: number;
  checked: boolean;
  disabled?: boolean;
}>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ size }) => getCSSUnit(size || 24)};
  width: ${({ size }) => getCSSUnit(size || 24)};
  border: 1px solid ${ColorsSystem.blue};
  border-radius: ${({ size }) => getCSSUnit(size || 12)};
  ${({ disabled }) =>
    disabled &&
    css`
      border-color: ${ColorsSystem.disabled};
      cursor: not-allowed;
    `}
`;

const CheckedBox = styled.div<{ size: number; disabled?: boolean }>`
  display: flex;
  height: ${({ size }) => getCSSUnit(size - 8 || 16)};
  width: ${({ size }) => getCSSUnit(size - 8 || 16)};
  background: ${ColorsSystem.blue};
  border-radius: ${({ size }) => getCSSUnit(size || 8)};
  ${({ disabled }) =>
    disabled &&
    css`
      background: ${ColorsSystem.disabled};
      cursor: not-allowed;
    `}
`;
export const RadioButton: React.FC<{
  onClick: () => void;
  checked: boolean;
  size?: number;
  text?: string;
  disabled?: boolean;
}> = ({ checked = false, onClick, text, size = 24, disabled }) => {
  return (
    <Container disabled={disabled}>
      <BoxContainer
        size={size}
        checked={checked}
        onClick={onClick}
        disabled={disabled}
      >
        {checked && <CheckedBox size={size} disabled={disabled} />}
      </BoxContainer>
      {text && <RegularText>{text}</RegularText>}
    </Container>
  );
};

export const SmallRadioButton: React.FC<{
  onClick: () => void;
  checked: boolean;
  text?: string;
  disabled?: boolean;
}> = ({ checked = false, onClick, text, disabled }) => {
  return (
    <Container disabled={disabled}>
      <BoxContainer
        size={18}
        checked={checked}
        onClick={onClick}
        disabled={disabled}
      >
        {checked && <CheckedBox size={18} disabled={disabled} />}
      </BoxContainer>
      {text && <SmallText>{text}</SmallText>}
    </Container>
  );
};
