import React, {
  ChangeEvent,
  forwardRef,
  InputHTMLAttributes,
  Ref,
} from "react";
import { FiSearch } from "react-icons/fi";
import styled from "styled-components";

import { TextInputContainer } from "./text_input";
import { InputWrapperWithErrorMessage } from "./input_wrappers";
import { device } from "../global_styles";
import { SharedInputProps } from "./input_config";
import { Icon } from "../icon";
import { ColorsSystem } from "../colors";

type IconInputProps = InputHTMLAttributes<HTMLInputElement> &
  SharedInputProps & {
    inputType?: string;
    onIconClick?: () => void;
    backgroundColor?: string;
  };

export const SearchInput = forwardRef(
  (
    {
      onValueChange,
      label,
      error,
      width,
      inputType = "text",
      onIconClick,
      backgroundColor = "transparent",
      ...props
    }: IconInputProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        onIconClick?.();
      }
    };

    return (
      <InputWrapperWithErrorMessage error={error} label={label} width={width}>
        <Container>
          <TextInputContainer
            {...props}
            type={inputType}
            isValid={!error}
            ref={ref}
            style={{ width: "100%", background: backgroundColor }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              props.onChange?.(event);
              onValueChange?.(event.currentTarget.value);
            }}
            onKeyDown={handleKeyDown}
          />
          <IconWrapper>
            <Icon
              Component={FiSearch}
              onClick={onIconClick}
              color={ColorsSystem.mediumGrey}
            />
          </IconWrapper>
        </Container>
      </InputWrapperWithErrorMessage>
    );
  }
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

  @media only screen and ${device.mobile} {
    width: 100%;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 9px;
`;
