import { createGlobalStyle } from "styled-components";
import { ColorsSystem } from "./colors";

const screenSize = {
  mobile: "37.5em",
  tabletPortrait: "56.25em",
  tabletLandscape: "75em",
};

export const device = {
  mobile: `(max-width: ${screenSize.mobile})`,
  tabletPortrait: `(max-width: ${screenSize.tabletPortrait})`,
  tabletLandscape: `(max-width: ${screenSize.tabletLandscape})`,
};

export const GlobalStyles = createGlobalStyle`
@font-face {
  font-family: "Branding-Semibold";
  src: local("Branding-Semibold"),
    url(src/assets/fonts/Branding-Semibold.ttf) format("truetype");
}
@font-face {
  font-family: "Branding-Medium";
  src: local("Branding-Medium"),
    url(src/assets/fonts/Branding-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Branding-Bold";
  src: local("Branding-Bold"),
    url(src/assets/fonts/Branding-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Branding-Light";
  src: local("Branding-Light"),
    url(src/assets/fonts/Branding-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Branding-MediumItalic";
  src: local("Branding-MediumItalic"),
    url(../../assets/fonts/Branding-MediumItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Branding-SemiboldItalic";
  src: local("Branding-SemiboldItalic"),
    url(../../assets/fonts/Branding-SemiboldItalic.ttf) format("truetype");
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  line-height: 1.2 !important;
}

/* tablet landscape */
@media only screen and (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}
/* tablet portrait */
@media only screen and ${device.tabletPortrait} {
  html {
    font-size: 50%;
  }
}
/* phone */
@media only screen and ${device.mobile} {
  html {
    font-size: 50%;
  }
}
@media only screen and (min-width: 112.5em) {
  html {
    font-size: 70%;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

body,
p,
div,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  background: transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: initial;
}

/* .textarea {
  min-height: 100%;
} */

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
/* Remove arrow/spinner native from input type number of browser  */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

/* .react-datetime-picker */
.react-datetime-picker {
  width: 100%;
  border: 1px solid ${ColorsSystem.darkGrey};
    border-radius: 8px;
  background-color: transparent;
  font-family: "Branding-Medium";
  font-size: 1.2rem;
    color: ${ColorsSystem.textRegularColor};
}

.react-datetime-picker__button {
  border: 0;
  background: transparent;
  padding: 0px 6px;
  color: ${ColorsSystem.textRegularColor};
}

.react-datetime-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: none;
  padding: 10px 3px;

   background-color: transparent;
  color: ${ColorsSystem.textRegularColor};
    height: 40px;
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 2rem;
  line-height: 24px;
  font-family: "Branding-Medium";
}

.react-datetime-picker__inputGroup__input {
   color: ${ColorsSystem.textRegularColor} !important;

  font-family: "Branding-Medium";
  ::placeholder  {
    font-size: initial;
   ${ColorsSystem.textRegularColor};
  }
}

::placeholder {
  color: #1a1a66;
  font-size: 1.2rem;
  font-family: "Branding-Medium" !important;
}

// ::scrollbar-horizontal {
//   scrollbar-width: thin;
//   scrollbar-color: ${ColorsSystem.powderBlueOne} ${ColorsSystem.lightGrey};
// }

// ::-webkit-scrollbar {
//   scrollbar-width:thin;
//   scrollbar-color: ${ColorsSystem.powderBlueOne} ${ColorsSystem.lightGrey};
// }

// ::-webkit-scrollbar-thumb {
//   background-color: ${ColorsSystem.lightGrey}; /* Couleur du bouton de défilement */
//   border-radius: 10px; /* Bord arrondi du bouton de défilement */
// }


`;
