import { OutlineButton } from "components/ui/buttons";
import { Panel } from "components/ui/cards";
import { PageWrapper } from "components/ui/containers/page";
import { VStack } from "components/ui/containers/stacks";
import { InputGroup } from "components/ui/inputs/input_wrappers";
import { RadioButton } from "components/ui/inputs/radio_button";
import { RegularText, SmallText } from "components/ui/texts";
import { useState } from "react";
import { UserInfos } from "types/user.types";
import generateExcelAndDownload from "utils/firebase/firebase.extracts";

type TypeExtractFormProps = {
  members: UserInfos[];
};
export const TypeExtractForm = ({ members }: TypeExtractFormProps) => {
  const [extractType, setExtractType] = useState<UserInfos["type"]>("0");

  const handleExportExcel = async (typeSelected: UserInfos["type"]) => {
    const membersWithSelectedType = members.filter(
      (member) => member.type === typeSelected
    );
    const date = new Date().toLocaleDateString().replaceAll("/", "-");
    const typeSelectedName =
      typeSelected === "0"
        ? "promotion"
        : typeSelected === "1"
        ? "vorace"
        : "secrétaire";
    await generateExcelAndDownload(
      membersWithSelectedType,
      `laurierExtract_${typeSelectedName}_du_${date}`
    );
  };

  return (
    <Panel>
      <VStack alignItems="center" gap={30}>
        <VStack>
          <RegularText>Choisissez le type de l'extract</RegularText>
          <SmallText>
            ( Nom, Prénom, Email, Adresse Civile, Adresse permanente et 10
            dernières cotisations )
          </SmallText>
        </VStack>
        <InputGroup>
          <RadioButton
            checked={extractType === "0"}
            onClick={() => setExtractType("0")}
            text={"Promotion"}
          />
          <RadioButton
            checked={extractType === "1"}
            onClick={() => setExtractType("1")}
            text={"Vorace"}
          />
          <RadioButton
            checked={extractType === "2"}
            onClick={() => setExtractType("2")}
            text={"Secrétaire"}
          />
        </InputGroup>
        <OutlineButton
          buttonSize="small"
          title="Télécharger l'extract"
          onClick={() => handleExportExcel(extractType)}
        />
      </VStack>
    </Panel>
  );
};
