import React from "react";
import { FiCheck } from "react-icons/fi";
import styled from "styled-components";
import { ColorsSystem } from "../colors";
import { RegularText } from "../texts";
import { getCSSUnit } from "../layouts_helpers";
import { Icon } from "../icon";

export const Checkbox: React.FC<{
  onClick?: () => void;
  checked: boolean;
  boxSize?: number;
  text?: string;
  rounded?: boolean;
}> = ({ onClick, text, boxSize = 24, checked = false, rounded = false }) => {
  const iconSize = boxSize - 4;
  return (
    <Container>
      <BoxContainer
        boxSize={boxSize}
        checked={checked}
        onClick={onClick}
        rounded={rounded}
      >
        {checked && (
          <Icon
            Component={FiCheck}
            color={ColorsSystem.white}
            size={iconSize}
          />
        )}
      </BoxContainer>
      {text && <Text>{text}</Text>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const BoxContainer = styled.div<React.ComponentProps<typeof Checkbox>>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ boxSize }) => getCSSUnit(boxSize || 32)};
  width: ${({ boxSize }) => getCSSUnit(boxSize || 32)};
  border: 1px solid ${ColorsSystem.blue};
  border-radius: ${({ rounded, boxSize = 32 }) =>
    rounded ? getCSSUnit(boxSize / 2) : "4px"};
  background: ${({ checked }) => (checked ? ColorsSystem.blue : "transparent")};
`;
const Text = styled(RegularText)`
  white-space: nowrap;
`;
