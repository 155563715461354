import DocViewer, { DocViewerRenderers, PDFRenderer } from "react-doc-viewer";
import { PDFViewer, PDFDownloadLink, StyleSheet } from "@react-pdf/renderer";
import { FilledButton } from "components/ui/buttons";
import { PageWrapper } from "components/ui/containers/page";
import { H4 } from "components/ui/headings";
import { MyDocument } from "./bulletin_component";

const styles = StyleSheet.create({
  button: {
    border: 1,
    borderColor: "black",
    borderRadius: 500,
    textAlign: "center",
    justifyContent: "center",
    height: 32,
  },
});
export const Bulletin = () => {
  return (
    <PageWrapper>
      <H4>Bulletin</H4>
      <div>
        {/* <PDFViewer>
          <MyDocument />
        </PDFViewer> */}
        <PDFDownloadLink
          style={{
            border: 1,
            borderColor: "black",
            borderRadius: 500,
            fontSize: 16,
          }}
          document={<MyDocument />}
          fileName="somename.pdf"
        >
          {({ blob, url, loading, error }) =>
            loading ? "Loading document..." : "télécharger le bulletin"
          }
        </PDFDownloadLink>
        {/* <FilledButton
          title="télécharger le bulletin"
          buttonSize={"small"}
        ></FilledButton> */}
      </div>
    </PageWrapper>
  );
};
