import { PageWrapper } from "components/ui/containers/page";
import { H4 } from "components/ui/headings";
import { RegularText, SmallText } from "components/ui/texts";
import React from "react";

export const ContactPage = () => {
  return (
    <PageWrapper>
      <H4>Contacts</H4>
      <RegularText>
        Vous rencontrez un problème à l'utilisation du site, veuillez contacter
        la développeuse web :
      </RegularText>
      <SmallText>celyne@giaume.com</SmallText>
      <RegularText>Pour tout autre question :</RegularText>
      <SmallText>herve@giaume.com</SmallText>
    </PageWrapper>
  );
};
