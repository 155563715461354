import { useContext } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import {
  SPACE_BETWEEN_STACK,
  SPACE_BETWEEN_INPUT,
  SPACE_BETWEEN_TEXT,
} from "components/ui/spacings";
import { VStack } from "components/ui/containers/stacks";
import { UserInfos } from "types/user.types";
import { updateEmailAuthAndUserDoc } from "utils/firebase/firebase.user";
import { ModalContext } from "components/ui/modal";
import { TextInput } from "components/ui/inputs/text_input";
import { FilledButton } from "components/ui/buttons";
import { UserContext } from "contexts/user_context";
import { H4 } from "components/ui/headings";
import { Label, RegularText } from "components/ui/texts";
import styled from "styled-components";

type InitialValuesType = {
  email: string;
  password: string;
};

export const UpdateUserEmailForm = ({ user }: { user: UserInfos }) => {
  const { handleModal } = useContext(ModalContext);
  const { setCurrentUser } = useContext(UserContext);

  const initialValues: InitialValuesType = {
    email: "",
    password: "",
  };
  const validationSchema = Yup.object().shape({
    landLineNumber: Yup.string(),
    phoneNumber: Yup.string(),
    email: Yup.string().email("Invalid email address"),
  });

  const handleUpdateEmail = async (values: InitialValuesType) => {
    const updatedUser = {
      ...user,
      email: values.email,
    };

    try {
      if (user && user.id && values.password) {
        await updateEmailAuthAndUserDoc(values.email, values.password, user.id);

        setCurrentUser(updatedUser as unknown as Partial<UserInfos>);
        handleModal();
      } else {
        throw new Error("User ID is undefined.");
      }
    } catch (error) {
      console.error("Error updating user:", error);
      alert("Oops, something went wrong.");
    }
  };

  return (
    <VStack alignItems="center">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleUpdateEmail}
      >
        {({ errors, touched, values }) => {
          return (
            <Form>
              <Wrapper>
                <H4>Modifier votre e-mail</H4>
                <VStack gap={SPACE_BETWEEN_TEXT}>
                  <RegularText>
                    Vous modifiez ici votre e-mail avec lequel vous vous
                    connectez au site.
                  </RegularText>
                  <RegularText>
                    Veuillez également renseigner votre mot de passe pour
                    autoriser le changement.
                  </RegularText>
                </VStack>
                <VStack gap={SPACE_BETWEEN_INPUT}>
                  <VStack style={{ paddingBottom: 10 }}>
                    <Label label={"E-mail actuel"} />
                    <RegularText>{user.email}</RegularText>
                  </VStack>
                  <TextInput
                    value={values.email}
                    id={"email"}
                    name={"email"}
                    type="text"
                    label={"Nouvel e-mail"}
                    error={errors.email}
                  />
                  <TextInput
                    value={values.password}
                    id={"password"}
                    name={"password"}
                    type="text"
                    label={"Mot de passe"}
                    error={errors.password}
                  />
                </VStack>
                <div>
                  <FilledButton
                    title="Sauvegarder"
                    buttonSize="small"
                    type="submit"
                  />
                </div>
              </Wrapper>
            </Form>
          );
        }}
      </Formik>
    </VStack>
  );
};

const Wrapper = styled(VStack)`
  align-items: center;
  gap: ${SPACE_BETWEEN_STACK}px;
  min-width: 300px;
  padding-bottom: 10px;
`;
