import React from "react";
import styled, { css } from "styled-components";
import { getCSSUnit } from "../layouts_helpers";

interface Props {
  gap?: React.CSSProperties["gap"];
  alignItems?: React.CSSProperties["alignItems"];
  justifyContent?: React.CSSProperties["justifyContent"];
  alignSelf?: React.CSSProperties["alignSelf"];
  wrap?: React.CSSProperties["flexWrap"];
  children: React.ReactNode;
  fullWidth?: boolean;
  fullHeight?: boolean;
}

const formatFlexAlignment = (
  value:
    | React.CSSProperties["alignItems"]
    | React.CSSProperties["justifyContent"]
) => {
  if (value === "end" || value === "start") {
    return `flex-${value}`;
  }

  return value;
};

const stackCSS = css<Props>`
  display: flex;
  ${({ gap }) =>
    gap &&
    css`
      gap: ${getCSSUnit(gap)};
    `}
  ${({ alignItems }) =>
    alignItems &&
    css`
      align-items: ${formatFlexAlignment(alignItems)};
    `}
  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${formatFlexAlignment(justifyContent)};
    `}
      ${({ alignSelf }) =>
    alignSelf &&
    css`
      align-self: ${formatFlexAlignment(alignSelf)};
    `}
  ${({ wrap }) =>
    wrap &&
    css`
      flex-wrap: ${wrap};
    `}
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100%;
    `}
`;
export const VStack = styled.div<Props>`
  ${stackCSS}
  flex-direction: column;
`;

export const HStack = styled.div<Props>`
  ${stackCSS}
  flex-direction: row;
`;
