import firebase from "firebase/compat/app";
import { Cotisation, LegacyCotisation } from "types/user.types";

export function filterNullObjects(array: any[]) {
  return array.filter((item) => item !== null);
}

export const getCotisationFromLegacyCotisation = (
  cotisation: LegacyCotisation | Cotisation
): Cotisation => {
  if ("date" in cotisation) {
    return cotisation;
  }
  return {
    date: cotisation.year,
    paid: cotisation.paid,
    paymentType: "non précisé",
    value: !cotisation.amount ? 0 : parseInt(cotisation.amount),
    valueInString:
      !cotisation.amount || cotisation.amount === "0"
        ? "zéro euro"
        : cotisation.amount === "20"
        ? "vingt euros"
        : cotisation.amount === "30"
        ? "trente euros"
        : cotisation.amount === "40"
        ? "quarante euros"
        : cotisation.amount === "50"
        ? "cinquante euros"
        : cotisation.amount === "60"
        ? "soixante euros"
        : cotisation.amount === "70"
        ? "soixante-dix euros"
        : cotisation.amount === "80"
        ? "quatre-vingt euros"
        : cotisation.amount === "90"
        ? "quatre-vingt-dix euros"
        : cotisation.amount === "100"
        ? "cent euros"
        : cotisation.amount === "110"
        ? "cent-dix euros"
        : cotisation.amount === "120"
        ? "cent-vingt euros"
        : cotisation.amount === "130"
        ? "cent-trente euros"
        : cotisation.amount === "140"
        ? "cent-quarante euros"
        : cotisation.amount === "150"
        ? "cent-cinquante euros"
        : cotisation.amount === "160"
        ? "cent-soixante euros"
        : cotisation.amount === "170"
        ? "cent-soixante-dix euros"
        : cotisation.amount === "180"
        ? "cent-quatre-vingt euros"
        : cotisation.amount === "190"
        ? "cent-quatre-vingt-dix euros"
        : cotisation.amount === "200"
        ? "deux-cents euros"
        : cotisation.amount,
  };
};

export const isStringNullOrEmpty = (str: string) => {
  return !str || str.trim() === "";
};

export const getStringFromDate = (date: Date) => {
  return date.toLocaleDateString("fr-FR");
};

export type FirebaseDate = { seconds: number; nanoseconds: number };

export const getDateFromFirebaseDateObject = (firebaseDate: {
  seconds: number;
  nanoseconds: number;
}): Date => {
  const milliseconds = firebaseDate.seconds * 1000;
  return new Date(milliseconds);
};

export function capitalizeFirstLetter(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export class DateDTO {
  static toDomain(date: firebase.firestore.Timestamp | FirebaseDate): number {
    if (!date) {
      return 0;
    }
    //@ts-expect-error We either receive a timestamp when reading from firestore, and an object from cloud functions
    const seconds = date.seconds ?? date._seconds;
    return seconds * 1000;
  }

  static toInfra(
    timestampInMilliseconds: number
  ): firebase.firestore.Timestamp {
    return firebase.firestore.Timestamp.fromMillis(timestampInMilliseconds);
  }
}
