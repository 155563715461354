import { VStack } from "components/ui/containers/stacks";
import { CreateMemberForm, UserInfosAndPassword } from "./create_member_form";
import { BluePanelTitle } from "components/ui/texts";
import { useContext } from "react";
import { ModalContext } from "components/ui/modal";
import { UserInfos } from "types/user.types";
import { updatingUserDoc } from "utils/firebase/firebase.user";
import { UpdateMemberForm } from "./update_member_form";
import { Option } from "types/global_types";

export const UpdateMemberFileModal = ({
  user,
  updateUser,
}: {
  user: UserInfos;
  updateUser: (arg: Option<UserInfos>) => void;
}) => {
  const { handleModal } = useContext(ModalContext);

  const handleSubmit = async (values: Partial<UserInfos>) => {
    const {
      lastName,
      firstName,
      login,
      landLineNumber,
      phoneNumber,
      civilAdress,
      region,
      diploma,
      birthdate,
      grade,
      DATECEN,
      DATE1LCL,
      DATE2CL,
      brigade,
      army,
      originArmy,
      maritalSituation,
      children,
      permanentAdress,
      repReunion,
      promotion,
      isDeceased,
      deceasedData,
      moreInfos,
    } = values;

    try {
      console.log("got user", user);

      if (user && user.id) {
        const updatedUser = await updatingUserDoc(user.id, {
          lastName,
          firstName,
          login,
          landLineNumber,
          phoneNumber,
          civilAdress,
          region,
          diploma,
          birthdate,
          grade,
          DATECEN,
          DATE1LCL,
          DATE2CL,
          brigade,
          army,
          originArmy,
          maritalSituation,
          children,
          permanentAdress,
          repReunion,
          promotion,
          isDeceased,
          deceasedData: isDeceased ? deceasedData : null,
          moreInfos,
        });
        console.log("updated user", updatedUser);

        updateUser(updatedUser as Option<UserInfos>);
        handleModal();
        alert("Utilisateur modifié avec succès!");
      } else {
        throw new Error("User ID is undefined.");
      }
    } catch (error: any) {
      console.log("error", error);
    }
  };
  return (
    <VStack>
      <BluePanelTitle>MODIFICATION FICHE MEMBRE PROMOTION</BluePanelTitle>
      <UpdateMemberForm handleSubmit={handleSubmit} member={user} />
    </VStack>
  );
};
