import { ref } from "firebase/storage";
import { db, storage } from "utils/firebase/firebase.utils";
import { uploadBytes, getDownloadURL } from "firebase/storage";
import { UserInfos } from "types/user.types";
import { Reunion, GalleryImage } from "types/reunions.types";
import { v4 as uuidv4 } from "uuid";
import {
  FieldValue,
  Firestore,
  doc,
  updateDoc,
  getDoc,
  addDoc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import firebase from "firebase/app";
export const REUNION_GALLERY_FOLDER_PATH = "/gallerie_reunion/";
export const MEETINGS_COLLECTION = "reunions";
export class FileUploadService {
  static generateFilePath(file: File): string {
    return `${REUNION_GALLERY_FOLDER_PATH}${Date.now()}_${file.name}`;
  }
  static async uploadFile(file: File): Promise<string> {
    try {
      const path = this.generateFilePath(file);
      const storageRef = ref(storage, path);
      const uploadResult = await uploadBytes(storageRef, file);
      const url = await getDownloadURL(uploadResult.ref);
      console.log("Uploaded file to", url);
      return url;
    } catch (error) {
      console.error("Error uploading file: ", error);
      throw error;
    }
  }

  static async addFileToReunion(
    imageURL: string,
    reunionId: string,
    currentUserId: string
  ): Promise<Reunion> {
    try {
      const imageDoc: GalleryImage = {
        id: uuidv4(),
        imageURL,
        uploadDate: Date.now(),
        uploaderUserId: currentUserId,
      };
      const reunionRef = doc(db, MEETINGS_COLLECTION, reunionId);
      const reunionSnapshot = await getDoc(reunionRef);
      const reunion = reunionSnapshot.data() as Reunion;
      if (!reunion) throw new Error("Réunion introuvable");
      await updateDoc(reunionRef, {
        ...reunion,
        galleryImages: [...reunion.galleryImages, imageDoc],
      });
      return reunion;
    } catch (error) {
      console.error("Error adding file to reunion: ", error);
      throw error;
    }
  }

  static async deleteReunionImage(
    reunionId: string,
    imageIdToDelete: string
  ): Promise<Reunion> {
    const reunionRef = doc(db, MEETINGS_COLLECTION, reunionId);
    const reunion = (await getDoc(reunionRef)).data() as Reunion;
    if (!reunion) throw new Error("Réunion introuvable");
    const imageIndex = reunion.galleryImages.findIndex(
      (image) => image.id === imageIdToDelete
    );
    if (imageIndex === -1) throw new Error("Image introuvable");
    reunion.galleryImages.splice(imageIndex, 1);
    await updateDoc(reunionRef, {
      ...reunion,
      galleryImages: [...reunion.galleryImages],
    });
    return reunion;
  }

  static async createReunion(reunionData: Reunion): Promise<void> {
    await setDoc(doc(db, MEETINGS_COLLECTION, reunionData.id), {
      ...reunionData,
    });
  }

  static async deleteReunion(reunionId: string): Promise<void> {
    const reunionRef = doc(db, MEETINGS_COLLECTION, reunionId);
    await deleteDoc(reunionRef);
  }
}
