import { FaCross } from "react-icons/fa";
import { Panel } from "components/ui/cards";
import { ColorsSystem } from "components/ui/colors";
import { H4, H5 } from "components/ui/headings";
import { ImageDisplay } from "components/ui/image_display";
import { HStack, VStack } from "components/ui/containers/stacks";
import { RegularText, SmallText } from "components/ui/texts";
import React, { useEffect, useState } from "react";
import { FiUser } from "react-icons/fi";
import styled from "styled-components";
import { Option } from "types/global_types";
import { UserInfos } from "types/user.types";
import { FirebaseUserGateway } from "utils/firebase/firebase.user";
import { IoPersonOutline } from "react-icons/io5";
import { PageWrapper } from "components/ui/containers/page";
import { CopyableText } from "components/ui/texts/copyable_text";
import { SearchInput } from "components/ui/inputs/search_input";
import Loader from "components/ui/loaders/loader";

export const Members = () => {
  const [members, setMembers] = useState<UserInfos[]>([]);
  const [valueSearch, setValueSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchMembers() {
      const usersGateway = new FirebaseUserGateway();
      const fetchedMembers = await usersGateway.getAllUsers();

      setMembers(fetchedMembers);
      setIsLoading(false);
    }
    fetchMembers();
  }, []);

  const promotionMembers = members.filter((member) => member.type === "0");

  const sortedMembers =
    members &&
    promotionMembers.sort((a, b) => {
      const lastNameA = a.lastName.toLowerCase();
      const lastNameB = b.lastName.toLowerCase();

      if (lastNameA < lastNameB) {
        return -1;
      }
      if (lastNameA > lastNameB) {
        return 1;
      }
      return 0;
    });

  const matchesSearch = (name: string): boolean => {
    if (!name) return false;
    return name.toLowerCase().includes(valueSearch.toLowerCase());
  };

  const filteredMembers = sortedMembers.filter(
    (member) =>
      matchesSearch(member.firstName) || matchesSearch(member.lastName)
  );

  return (
    <PageWrapper>
      <H4>Les membres de la promotion</H4>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <SearchInput
            placeholder={"chercher un membre"}
            onChange={(e) => setValueSearch(e.target.value)}
            value={valueSearch}
          />
          {filteredMembers && (
            <HStack justifyContent="center" wrap="wrap" gap={10}>
              {filteredMembers.map((member) => {
                return (
                  <Panel
                    key={member.id}
                    style={{
                      position: "relative",
                    }}
                  >
                    {member.isDeceased && (
                      <VStack
                        style={{ position: "absolute", top: 10, right: 10 }}
                      >
                        <FaCross size={20} color={ColorsSystem.darkGrey} />
                      </VStack>
                    )}
                    <VStack alignItems="center" gap={20}>
                      <UserAvatar userImageUrl={null}></UserAvatar>
                      <VStack
                        style={{ width: 200 }}
                        alignItems="center"
                        gap={10}
                      >
                        <RegularText textAlign="center">
                          {member.lastName + " " + member.firstName}
                        </RegularText>
                        {member.email === "" ? (
                          <SmallText>pas d'email associé</SmallText>
                        ) : (
                          <CopyableText
                            value={member.email}
                            elementToCopy={member.email}
                            hoverMessage="cliquer pour copier l'email"
                          />
                        )}
                      </VStack>
                    </VStack>
                  </Panel>
                );
              })}
            </HStack>
          )}
        </>
      )}
    </PageWrapper>
  );
};

const UserAvatar = ({ userImageUrl }: { userImageUrl: Option<string> }) => {
  return (
    <AvatarContainer>
      {userImageUrl ? (
        <ImageDisplay
          src={userImageUrl}
          alt={"image de profil de l'utilisateur"}
        />
      ) : (
        <IoPersonOutline size={40} color={ColorsSystem.lightGrey} />
      )}
    </AvatarContainer>
  );
};

const AvatarContainerSize = 60;
const AvatarContainer = styled.div`
  display: flex;
  border-radius: ${AvatarContainerSize / 2}px;
  border: 1px solid ${ColorsSystem.lightGrey};
  width: ${AvatarContainerSize}px;
  height: ${AvatarContainerSize}px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
`;
