export const validateEmail = (email: string): string | null => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!email) {
    return "L'adresse email est requise";
  }

  if (!emailRegex.test(email)) {
    return "Adresse email invalide";
  }
  return null;
};
