import React from "react";
import { FiLoader } from "react-icons/fi";
import styled, { css } from "styled-components";

import {
  largeStyle,
  mediumStyle,
  smallStyle,
  commonButtonStyle,
  ButtonProps,
  extraSmallStyle,
} from "./config";
import { Icon } from "../icon";
import { ColorsSystem } from "../colors";
import { getCSSUnit } from "../layouts_helpers";

export const FilledButton: React.FC<ButtonProps> = ({
  buttonSize = "medium",
  title = "",
  disabled = false,
  loading = false,
  leftIcon = undefined,
  rightIcon = undefined,
  href,
  ...props
}) => {
  return (
    <FilledContainer
      {...(href
        ? ({
            as: "a",
            href,
          } as any)
        : {})}
      disabled={disabled}
      buttonSize={buttonSize}
      {...props}
    >
      {loading ? (
        <Icon
          Component={FiLoader}
          size={
            buttonSize === "small" ? 16 : buttonSize === "extraSmall" ? 12 : 24
          }
        />
      ) : (
        <>
          {leftIcon && (
            <Icon
              Component={leftIcon}
              size={
                buttonSize === "small"
                  ? 16
                  : buttonSize === "extraSmall"
                  ? 12
                  : 24
              }
            />
          )}
          {title && title}
          {rightIcon && (
            <Icon
              Component={rightIcon}
              size={
                buttonSize === "small"
                  ? 16
                  : buttonSize === "extraSmall"
                  ? 12
                  : 24
              }
            />
          )}
        </>
      )}
    </FilledContainer>
  );
};

const FilledContainer = styled.button<ButtonProps>`
  ${commonButtonStyle};
  background-color: ${({ color }) => color || ColorsSystem.blue};
  color: ${ColorsSystem.white};
  width: ${({ width }) => (width ? getCSSUnit(width) : "max-content")};
  ${({ buttonSize }) =>
    buttonSize === "large"
      ? largeStyle
      : buttonSize === "medium"
      ? mediumStyle
      : buttonSize === "extraSmall"
      ? extraSmallStyle
      : smallStyle};

  &:hover {
    background-color: ${ColorsSystem.powderBlueOne};
    color: ${ColorsSystem.white};
  }

  // &:active {
  //   background-color: ${ColorsSystem.gold};
  // }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${ColorsSystem.lightGrey};
      border: 2px solid ${ColorsSystem.lightGrey};
      cursor: not-allowed;
      &:hover {
        background-color: ${ColorsSystem.lightGrey};
        border: 2px solid ${ColorsSystem.lightGrey};
      }
      &:active {
        background-color: ${ColorsSystem.lightGrey};
        border: 2px solid ${ColorsSystem.lightGrey};
      }
      cursor: not-allowed;
    `};

  ${({ error }) =>
    error &&
    css`
      background-color: ${ColorsSystem.red};
      &:hover {
        background-color: ${ColorsSystem.red};
      }
      &:active {
        background-color: ${ColorsSystem.red};
      }
    `};
  ${({ centered }) =>
    centered &&
    css`
      align-self: center;
    `};
`;
