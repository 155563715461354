// import Tippy from "@tippy.js/react";
import Tippy from "@tippyjs/react";
import React from "react";
import { IconType } from "react-icons";
import { IoCopyOutline } from "react-icons/io5";
import styled from "styled-components";
import { Icon } from "../icon";
import { ColorsSystem } from "../colors";

export const IconInteraction = ({
  icon,
  interaction,
  content,
}: {
  icon: IconType;
  interaction?: () => void;
  content?: string;
}) => {
  return (
    <Tippy content={<TooltipStyled>{content}</TooltipStyled>} placement="left">
      <Container onClick={interaction} isCopyable={icon === IoCopyOutline}>
        <Icon Component={icon} size={12} />
      </Container>
    </Tippy>
  );
};

const Container = styled.div<{ isCopyable: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid ${ColorsSystem.mediumGrey};
  background-color: ${ColorsSystem.disabled};
  width: 24px;
  height: 24px;
  padding: 2px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:active {
    ${({ isCopyable: isCopyable }) =>
      isCopyable &&
      `
      transform: scale(0.9);
      opacity: 0.8;
    `}
  }
`;
const TooltipStyled = styled.div`
  background-color: ${ColorsSystem.powderBlueOne};
  color: ${ColorsSystem.white};
  font-size: 16px;
  padding: 8px;
  border-radius: 8px;
  font-family: "Branding-Medium";
  text-align: justify;
`;

export const CustomisableIconInteraction = ({
  children,
  content,
  style,
}: {
  children: React.ReactNode;
  content?: string;
  style?: React.CSSProperties;
}) => {
  const defaultStyle: React.CSSProperties = {
    backgroundColor: ColorsSystem.lightGrey,
    color: ColorsSystem.white,
    fontSize: "10px",
    padding: "8px",
    borderRadius: "8px",
    textAlign: "justify",
    fontFamily: "Branding-Medium",
  };
  const mergedStyle = { ...defaultStyle, ...style };
  return (
    <Tippy content={<div style={mergedStyle}>{content}</div>} placement="left">
      <div>{children}</div>
    </Tippy>
  );
};
