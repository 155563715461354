import React from "react";
import { SignInForm } from "../../components/sign_in/sign_in_form";

export const Authentication = () => {
  return (
    <div className="authentication-container">
      <SignInForm></SignInForm>
    </div>
  );
};
