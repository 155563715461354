import React from "react";
import styled, { css } from "styled-components";
import { ColorHex, ColorsSystem } from "./colors";
import { CustomTextProps, H4, H5, TextProps } from "./headings";
import { SPACE_BETWEEN_TEXT } from "./spacings";
import { Color } from "react-color";

const StyledRegularText = styled.p<CustomTextProps>`
  font-size: 16px;
  line-height: 20px;
  font-family: ${({ fontFamily }) => fontFamily ?? "Montserrat, sans-serif"};
  color: ${({ color }) => color ?? ColorsSystem.darkGrey};
  text-align: ${({ textAlign }) => textAlign ?? "left"};
  align-self: ${({ alignSelf }) => alignSelf ?? "auto"};
  font-weight: ${({ fontWeight }) => fontWeight ?? 500};
  margin: 0;
  padding: 0;
  ${(props) =>
    props.crop &&
    css`
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: ${props.crop};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      overflow-wrap: break-word;
    `}
`;

export const RegularText: React.FC<TextProps> = ({ children, ...props }) => {
  return <StyledRegularText {...props}>{children}</StyledRegularText>;
};

export const WarningText: React.FC<TextProps> = ({ children, ...props }) => {
  return (
    <StyledRegularText {...props} color={"red"} fontWeight={800}>
      {children}
    </StyledRegularText>
  );
};

const StyledSmallText = styled.p<CustomTextProps>`
  font-size: 12px;
  line-height: 16px;
  font-family: ${({ fontFamily }) => fontFamily ?? "Montserrat, sans-serif"};
  color: ${({ color }) => color ?? ColorsSystem.darkGrey};
  text-align: ${({ textAlign }) => textAlign ?? "left"};
  align-self: ${({ alignSelf }) => alignSelf ?? "auto"};
  margin: 0;
  padding: 0;
  ${(props) =>
    props.crop &&
    css`
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: ${props.crop};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      overflow-wrap: break-word;
    `}
`;

export const SmallText: React.FC<TextProps> = ({ children, ...props }) => {
  return <StyledSmallText {...props}>{children}</StyledSmallText>;
};

interface LinkTextProps extends TextProps {
  onClick: () => void;
}

export const LinkSmallText: React.FC<LinkTextProps> = ({
  children,
  onClick,
  ...props
}) => {
  return (
    <StyledLinkText {...props} onClick={onClick}>
      {children}
    </StyledLinkText>
  );
};

const StyledLinkText = styled.p<CustomTextProps>`
  padding-top: 6;
  font-family: ${({ fontFamily }) => fontFamily ?? "Montserrat, sans-serif"};
  font-size: 1.2rem;
  &:hover {
    cursor: pointer;
    color: ${ColorsSystem.gold};
  }
  color: ${ColorsSystem.white};
  cursor: pointer;
`;

export const Label = ({
  label,
  color,
}: {
  label: string;
  color?: ColorHex;
}) => {
  return <StyledLabel color={color}>{label}</StyledLabel>;
};

const StyledLabel = styled(H5)`
  padding-bottom: 4px;
  color: ${({ color }) => color ?? ColorsSystem.mediumGrey};
`;

export const BluePanelTitle = styled(H4)`
  color: ${ColorsSystem.blue};
  padding-bottom: 20px;
`;
